import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { ADD_REQUIREMENT_URL, LIST_REQUIREMENT_URL, PARTNER_URL, REQUIREMENT_ASSIGN_URL, REQUIREMENT_URL } from "../../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Link } from "react-router-dom";
// import Search from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ApiCall } from "../../../Config/API";

import { PaginationContent } from "../../../Components/PaginationContent"
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";
import { TableHeader } from "../../../Components/TableHeader";
const Headoption = [

    { value: 'Robert', label: 'Robert' },

];


export const Assign_Requirement_List = () => {

    const [Headreq, setHeadreq] = useState(null);

    const [RequirementList, setRequirementList] = useState([]);

    const [Assignnedlist, setAssignnedlist] = useState([]);

    const [notesmodal, setNotesmodal] = useState(false);

    const [notestext, setNotestext] = useState('');
    const [noteserror, setNoteserror] = useState({});

    const [requirement_id, setRequirement_id] = useState('');
    const [checkbox, setCheckbox] = useState(false);

    const navigate = useNavigate()

    const [Headoption, setHeadoption] = useState([]);


    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });

    const [pagevalues, setPagevalues] = useState({
        from: 1,
        to: 10,
        total: 10
    });



    useEffect(() => {

        // var post_data = {
        //     per_page: 10,
        //     page: 1,
        //     search: '',
        //     sort_by: "",
        //     sort_direction: ''
        // }
        getAll(query);

    }, []);
    const { apiState } = useApiResponseContext();

    const { dispatch } = useApiResponseContext();

    const getAll = async (query) => {


        var data = {
            "method": "get",
            "route_name": "requirement_assigns?",
            'query': query

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {
            setRequirementList(Response.success.requirements.data)

            var array = Response.success.head_recruiters.map((e) => ({

                value: e.id,
                label: e.name
            }))

            setHeadoption(array)

            dispatch({ type: 'SUCCESS', payload: Response.success.requirements });

        }
        else {
            console.log("Error", Response.error)

        }



    };



    const selectOption = async (id, e) => {


        // console.log("Assignnedlist", Assignnedlist)


        const data = [...Assignnedlist]




        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = Assignnedlist.filter(item => item !== id);
            // console.log(ssidArray);

            setAssignnedlist(ssidArray)


        } else {
            data.push(id)

            // console.log("data", data)

            setAssignnedlist(data)


        }



    }


    console.log("Assignnedlist", Assignnedlist)

    const AssignHeadrecruiter = async () => {



        var data = {
            "method": "post",
            "route_name": "requirement_assigns",
            "body": {
                "head_recruiter_id": Headreq.value,
                "requirement_ids": Assignnedlist
            }

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)

            swal("Success!", "Requirements Assigned Sucessfully!", "success")
            getAll(query)
            setCheckbox(true)
            setAssignnedlist([])
            setHeadreq(null)
            setTimeout(() => {
                swal.close();
                setCheckbox(false)
            }, 1500);





        }
        else {
            console.log("Error", Response.error.errors)

        }



    }



    const NotesSendApi = async () => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + requirement_id + "/requirement_notes",
            "body": {
                'body': notestext
            }
        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Your notes added successfully.!", "success")
            setNotesmodal(false)
            setNotestext('')
            setNoteserror({})
            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error.errors)
            setNoteserror(Response.error.errors)
        }
    }



    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: query.sort_by,
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }

    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);



    }



    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Requirement No.",
            icon: "Yes",
            key: "requirement_no",
            sort: ""
        },
        {
            name: "Requirement Title",
            icon: "Yes",
            key: "title",
            sort: ""
        },
        // {
        //     name: "Location",
        // },
        {
            name: "Partner",
        },
        {
            name: "Partner Rate",
            icon: "Yes",
            key: "partner_price",
            sort: ""
        },
        {
            name: "Head Recruiter",
        },
        {
            name: "Candidates",
            icon: "Yes",
            key: "candidates_count",
            sort: ""
        },

        {
            name: "Active Status",
           
        },
        {
            name: "Current Status",

        },

        {
            name: "Action",
            notes: "no"
        },
        {
            name: "Assign",
        },
        {
            name: "Notes",
        },
    ]




    const statusChangeFunc = async (req_id, index) => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + req_id + "/status"

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...RequirementList]
            array[index].active_status.name = (array[index].active_status.name != "Active" ? "Active" : 'In active')
            setRequirementList(array)


            swal("Success!", "Candidate Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }
    }
    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Requirement</h6>
                                    <div>

                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/requirements" ><i className="fa fa-arrow-left"></i> Back</Link>

                                    </div>
                                </header>

                                <div style={{ margin: '15px' }}>
                                    <div className=" form-textSetting">
                                        <label for="inputPassword3" className="col-sm-2 control-label">Assign to Head Recruiter</label>
                                        <div className="col-sm-4">

                                            <div className="">
                                                <Select
                                                    placeholder="[-Search Head Recruiter-]"
                                                    // defaultValue={Headreq}
                                                    value={Headreq ? Headoption.find(option => option.value === 22) : null}
                                                    onChange={setHeadreq}
                                                    options={Headoption}
                                                />
                                            </div>

                                        </div>
                                        {Headreq && Assignnedlist.length > 0 ?
                                            <div onClick={() => AssignHeadrecruiter()} className=" btn btn-default assign_addbtn "  >Assign</div>
                                            : null}
                                    </div>
                                </div>

                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />


                                <div className="table-colorOrgan mob-overflow">
                                    <table >

                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />

                                        {/* <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">Location</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Partner</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Partner Rate</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Head Recruiter</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Candidates</th>
                                                <th className="color">Action</th>
                                                <th className="color">Assign</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            {RequirementList.map((e, i) => (
                                                <tr key={i} className="tabledata">

                                                    {query.sort_direction == "asc" && query.sort_by == "" ?
                                                        <td style={{ width: 67 }} className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                        :
                                                        <td style={{ width: 67 }} className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                    }
                                                    {/* <td>{((query.page - 1) * 10 + 1) + i}</td> */}
                                                    <td style={{ width: 144 }} >
                                                        <Link to={`/assigned_requirement_view/${e.id}`}  > {e.requirement_no}</Link>
                                                    </td>
                                                    <td style={{ width: 149 }}>{e.title}</td>
                                                    {/* <td style={{ width: 100 }}>{e.location}</td> */}
                                                    <td style={{ width: 100 }}>{e.partner.name}</td>
                                                    <td style={{ width: 130 }}> $ {e.partner_price}</td>
                                                    <td style={{ width: 110 }}>{e.head_recruiter.name}</td>

                                                    <td style={{ width: 110 }}>({e.candidates_count})</td>
                                                    <td style={{ width: 110 }}>
                                                        <span
                                                         style={{ marginLeft: 10 }}
                                                            className={
                                                                e.active_status.name === 'Active'
                                                                    ? 'green-status'
                                                                    : e.active_status.name === 'In active'
                                                                        ? 'red-status'
                                                                        : e.active_status.name === 'Pending'
                                                                            ? 'yellow-status'
                                                                            : e.active_status.name === 'Trash'
                                                                            && 'red-status'
                                                            }
                                                        >
                                                            {e.active_status.name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {e.active_status.name === 'Trash' ?
                                                            <p style={{ marginLeft: 40 }}>-</p>
                                                            :
                                                            <Link onClick={() => statusChangeFunc(e.id, i)}  >{e.active_status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="dropdown history_dropdown">
                                                            <button className="dropdown_alert">
                                                                <span className="">▼</span>
                                                            </button>
                                                            <div className="dropdown-content">
                                                                {/* <a onClick={() => ViewFunc1(e.id)}>View</a> */}
                                                                <Link to={`/assigned_requirement_view/${e.id}`}  > View</Link>
                                                                <Link to={`/assigned_requirement_edit/${e.id}`}  > Edit</Link>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: 67 }}>
                                                        <input checked={checkbox ? false : undefined} disabled={e.active_status.name == "Active" ? (e.head_recruiter.requirement_status.name == "New" ? false : true) : true} type="checkbox" onChange={() => selectOption(e.id)} />
                                                    </td>
                                                    <td style={{ width: 67 }}>
                                                        <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                            <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}




                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="Previous_organ ">
                                    <div>
                                        <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Showing 1 to 2 of 2 entries</h5>
                                    </div>
                                    <div>
                                        <button className="Next_button"> ← Previous</button>
                                        <button className="no1_button">1</button>
                                        <button className="Next_button">Next →</button>
                                    </div>
                                </div> */}


                                <PaginationContent page_show={pagevalues} PageClick={(value) => PageOnpresFunc(10, value)} />



                            </section>

                        </div>





















                    </div>
                </div>
            </div>


            {notesmodal ?
                <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                : null}

            {/* <Modal
                show={notesmodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setNotesmodal(false)}
            >
                <Modal.Header className="modal-header" closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Requirement Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <textarea
                        placeholder="Enter your notes..."
                        onChange={(e) => setNotestext(e.target.value)}
                        className="form-control inputbox-height validate[required] note-style" required id="address" cols="5" name="data[User][address]" >

                    </textarea>
                    {noteserror.body ?
                        <span style={{ marginTop: 10 }} className="span-error" >{noteserror.body}</span>
                        : null}
                   
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => NotesSendApi()} type="button" className="btn btn-default Organ_addbtn">Post Notes</button>

                    
                </Modal.Footer>
            </Modal> */}




        </div>










    );
}

