

import React, { useEffect } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,

  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem
} from "cdbreact";
import { NavLink } from "react-router-dom";
// import { Image } from "react-router-dom";
import "./Sidebar.css"
import './pages/allstyle.css'
import { Link, useLocation, useRouteMatch } from "react-router-dom";

import logoImage from "./assets/loogoo.png";
import { useState } from "react";
import { useApiResponseContext } from "./Config/API/ApiResponseContext";
import { ApiCall } from "./Config/API";
const Sidebar = () => {

  const type = localStorage.getItem("user_type")
  const logo = localStorage.getItem("Logo")

  const location = useLocation();
  const { dispatch } = useApiResponseContext();
  console.log("location", location)


  const organization = [
    "/organizations",
    "/organizations_add",
    `/organization_edit`,
    `/organization_view`
  ];
  const users = [
    "/user",
    "/user_add",
    `/user_edit`,
    `/user_view`
  ];
  const emailcontnet = [
    "/emailcontents",
    "/emailcontents_edit",

  ];

  const staffs = [
    "/staffs",
    "/staffs_add",
    "/staffs_edit",
    `/staffs_view`,

  ];
  const jobtype = [
    "/jobtype",
    "/jobtype_add",
    "/jobtype_edit",

  ];
  const visatype = [
    "/visatype",
    "/visatype_add",
    "/visatype_edit",

  ];

  const jobHistoryRoutes = [
    "/jobhistory",
    "/jobhistory_view",
    "/jobhistory_edit",
    "/jobhistory_candidates_view",
  ]

  const candiateshist = [
    "/candidates",
    "/candidates_view",

  ];

  const partnerRoutes = [
    "/partners",
    "/partners_add",
    "/partners_edit",
    "/partners_view",
  ];

  const requirementRoutes = [
    "/requirements",
    "/requirements_add",
    "/requirements_edit",
    "/requirements_view",
    "/assigned_requirement",
    "/assigned_requirement_view",
    "/assigned_requirement_edit",
    "/req_candidates_view",
    "/req_candidates_edit",
  ];

  const shortlistRoutes = [
    "/shortlists",
    "/shortlists_candidateslist",
    "/my_shortlists",
    "/partner_shortlists",
    "/partner_shortlists_view",
    "/partner_shortlists_edit",
    "/shortlists_req_candidates_view"
  ];

  const Acc_candidateRoutes = [
    "/candidates",
    "/candidates_add",
    "/candidates_view",
    "/candidates_edit",
  ];
  const headRequirementRoutes = [
    "/head_requirements",
    "/head_requirements_view",
    "/head_myrequirements_list",
    "/head_myrequirement_view",
    "/head_assignrequirements_list",
    "/head_assignrequirements_view",
    "/head_selectCandidate_add",
  ];

  const recruiterRoutes = [
    "/recruiter_requirements",
    "/recruiter_selectcandidate_add",
    "/recruiter_requirements_view",
  ];
  const reportRoutes = [
    "/candidates_reports",
    "/reports"

  ];
  const [selected, setselcted] = useState(false)
  const [reportselected, setreportselected] = useState(false)

  const [userselected, setUserselcted] = useState(false)
  const [emailselected, setEmailselcted] = useState(false)
  const [staffselected, setStaffselcted] = useState(false)
  const [jobtypeselected, setJobtypeselectedselcted] = useState(false)

  const [visatypeselected, setvisatypeselectedselcted] = useState(false)
  const [jobhistselected, setjobhistselcted] = useState(false)

  const [candiateshistselected, setCandiateshistselcted] = useState(false)

  const [partnerselected, setPartnerselcted] = useState(false)

  const [requirementsselected, setRequirementsselected] = useState(false)
  const [shortselected, setShortselected] = useState(false)
  const [acc_candi_selected, setAcc_candi_selected] = useState(false)


  const [head_req_selected, setHead_req_selected] = useState(false)


  const [rec_req_selected, setRec_req_selected] = useState(false)


  const [noti_status, setNoti_status] = useState(false)


  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const modifiedPath = `/${pathSegments[1]}`;
    console.log("modifiedPath", modifiedPath);

    GetUrls(modifiedPath)

  }, [location])



  const GetUrls = async (pathname) => {

    const isPresent = organization.includes(pathname)
    setselcted(isPresent)

    const userstatus = users.includes(pathname)
    setUserselcted(userstatus)

    const emailstatus = emailcontnet.includes(pathname)
    setEmailselcted(emailstatus)

    const staffsstatus = staffs.includes(pathname)
    setStaffselcted(staffsstatus)

    const jobtypestatus = jobtype.includes(pathname)
    setJobtypeselectedselcted(jobtypestatus)

    const visatypestatus = visatype.includes(pathname)
    setvisatypeselectedselcted(visatypestatus)

    const jobhiststatus = jobHistoryRoutes.includes(pathname)
    setjobhistselcted(jobhiststatus)

    const candihiststatus = candiateshist.includes(pathname)
    setCandiateshistselcted(candihiststatus)

    const partnerstatus = partnerRoutes.includes(pathname)
    setPartnerselcted(partnerstatus)

    const req_status = requirementRoutes.includes(pathname)
    setRequirementsselected(req_status)

    const shortlist_status = shortlistRoutes.includes(pathname)
    setShortselected(shortlist_status)

    const candi_status = Acc_candidateRoutes.includes(pathname)
    setAcc_candi_selected(candi_status)
    const head_status = headRequirementRoutes.includes(pathname)
    setHead_req_selected(head_status)

    const rec_status = recruiterRoutes.includes(pathname)
    setRec_req_selected(rec_status)

    const report_status = reportRoutes.includes(pathname)
    setreportselected(report_status)



  }


  // const [darkMode, setDarkMode] = useState(
  //   () => JSON.parse(localStorage.getItem("Notification_Show")) ?? false
  // );

  const { apiState } = useApiResponseContext();


  // console.log("darkMode", darkMode);


  console.log("Notificationssssss", (apiState))


  const Notification_Show = localStorage.getItem("Notification_Show");


  console.log("Notification_ShowF", JSON.parse(Notification_Show));

  const [sidelogo, setSidelogo] = useState("")

  useEffect(() => {


    GetProfileApi()

    console.log("apiState", (apiState?.profile_data))


  }, [apiState?.profile_data])

  const GetProfileApi = async () => {



    var data = {
      "method": "GET",
      "route_name": "/profile/edit",

    }

    const Response = await ApiCall(data, dispatch)
    if (Response.success) {


      console.log("loggggggggggggggg", Response.success.user.organization)
      if (Response.success.user.organization) {
        setSidelogo(Response.success.user.organization.logo)

      }
      // dispatch({ type: 'PROFILE', payload: Response.success });
    }
    else {
      console.log("Error", Response.error)

    }

  }










  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#162031"
      >
        <CDBSidebarHeader
          prefix={
            <i className="fa fa-bars fa-large"></i>
          }
        >
          <a href="/" className="text-decoration-none" style={{ color: "inherit" }}>

            {type == "Super admin" ?
              <img src={logoImage} className="left-logo" />
              :
              <img alt="panelImage" src={sidelogo} className="left-logo" />
            }
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">

          <CDBSidebarMenu>
            <NavLink
              exact
              to="/profilesettings"
              className={location.pathname == "/profilesettings" ? "activeClicked" : ""}
            >
              <CDBSidebarMenuItem icon="fas fa-cogs" className='fourheading' >
                Setting
              </CDBSidebarMenuItem>

            </NavLink>


          </CDBSidebarMenu>
          {type == "Super admin" ?
            <CDBSidebarMenu>



              <Link
                exact
                to="/emailcontents"
                className={emailselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="far fa-envelope" className='fourheading'>
                  Email Mgmt
                </CDBSidebarMenuItem>
              </Link>
              <Link
                to="/organizations"
                className={selected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-sitemap" className='fourheading'>
                  Organization Mgmt
                </CDBSidebarMenuItem>
              </Link>
              <Link
                exact
                to="/user"
                className={userselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-user-cog" className='fourheading'>
                  Admin Mgmt
                </CDBSidebarMenuItem>
              </Link>


            </CDBSidebarMenu>
            : null}
          {type == "Admin" ?
            <CDBSidebarMenu>

              <Link
                exact
                to="/staffs"
                className={staffselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
                  Staff Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/jobtype"
                className={jobtypeselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Job Type Mgmt
                </CDBSidebarMenuItem>
              </Link>


              <Link
                exact
                to="/visatype"
                className={visatypeselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-plane" className='fourheading'>
                  Visa Type Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/jobhistory"
                className={jobhistselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="far fa-clock" className='fourheading'>
                  Job History
                </CDBSidebarMenuItem>
              </Link>
              <NavLink
                exact
                to="/reports"
                className={reportselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-file-alt mr-2" className='fourheading'>
                  Report Mgmt
                </CDBSidebarMenuItem>
              </NavLink>


              <Link
                exact
                to="/candidates"
                className={candiateshistselected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-users" className='fourheading'>
                  Candidate History
                </CDBSidebarMenuItem>
              </Link>


            </CDBSidebarMenu>
            : null}
          {type == "Account manager" ?
            <CDBSidebarMenu>



              <Link
                exact
                to="/partners"
                className={partnerselected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-user" className='fourheading'>
                  Partner Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/requirements"
                className={requirementsselected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/shortlists"
                className={shortselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-check-circle mr-2" className='fourheading'>
                  Shortlist Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <NavLink
                exact
                to="/reports"
                className={reportselected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-file-alt mr-2" className='fourheading'>
                  Report Mgmt
                </CDBSidebarMenuItem>
              </NavLink>

              <Link
                exact
                to="/candidates"
                className={acc_candi_selected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-users mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </Link>


            </CDBSidebarMenu>
            : null}
          {type == "Head recuiter" ?
            <CDBSidebarMenu>



              <Link
                exact
                to="/head_requirements"
                className={head_req_selected == true ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/candidates"
                className={acc_candi_selected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </Link>

              {/* <Link
                exact
                to="/notifications"
                className={location.pathname == "/notifications" ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-users mr-2" className='fourheading notifications'>

                  Notification
                </CDBSidebarMenuItem>
              </Link> */}

            </CDBSidebarMenu>
            : null}
          {type == "Recuiter" ?
            <CDBSidebarMenu>



              <Link
                exact
                to="/recruiter_requirements"
                className={rec_req_selected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-briefcase" className='fourheading'>
                  Requirement Mgmt
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/candidates"
                className={acc_candi_selected == true ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-user mr-2" className='fourheading'>
                  Candidate Mgmt
                </CDBSidebarMenuItem>
              </Link>

              {/* <Link
                exact
                to="/notifications"
                className={location.pathname == "/notifications" ? "activeClicked" : ""}

              >
                <CDBSidebarMenuItem icon="fas fa-users mr-2" className='fourheading notifications'>

                  Notification
                </CDBSidebarMenuItem>
              </Link> */}

            </CDBSidebarMenu>
            : null}
          {JSON.parse(Notification_Show) == true ?
            <CDBSidebarMenu>
              <Link
                exact
                to="/notifications"
                className={location.pathname == "/notifications" ? "activeClicked" : ""}
              >
                <CDBSidebarMenuItem icon="fas fa-exclamation-triangle" className='notifications'>
                  Notification
                </CDBSidebarMenuItem>
              </Link>


            </CDBSidebarMenu>
            : null}

        </CDBSidebarContent>


      </CDBSidebar >
    </div >
  );
}

export default Sidebar;
