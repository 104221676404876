import React, { useEffect, useState } from "react";
import {
    CDBBtn,
    CDBProgress,
    CDBTable,
    CDBTableHeader,
    CDBTableBody,
    CDBContainer,
    CDBLink
} from "cdbreact";

import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "../../Dashboard.css";
import Select from 'react-select';
import { PARTNER_URL } from "../../../constant";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ApiCall } from "../../../Config/API";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";



export const PartnerEdit = () => {

    // const [Statename, setStatename] = useState(null);

    const navigate = useNavigate()
    const [StaffVal, setStafftVal] = useState('');
    const [StaffVal1, setStafftVal1] = useState('');
    const [StaffVal2, setStafftVal2] = useState('');
    const [StaffVal3, setStafftVal3] = useState('');
    const [StaffVal4, setStafftVal4] = useState('');
    const [StaffVal5, setStafftVal5] = useState('');
    const [StaffVal6, setStafftVal6] = useState('');
    const [StaffVal7, setStafftVal7] = useState('');
    const [StaffVal8, setStafftVal8] = useState('');

    const { dispatch } = useApiResponseContext();
    const [documentarray, setdocumentarray] = useState([
        {

            "name": '',
            "file": ""
        }
    ]);
    const [error, setError] = useState({});


    const [Soptions, setoptions] = useState([]);

    const [selectedOption1, setSelectedOption1] = useState('');

    const { id } = useParams()

    const Partner_id = id

    const onchangefun = (e) => {


        console.log("e.target.value", e)
        setSelectedOption1(e.value)


    };

    useEffect(() => {


        fetchData();
    }, []);



    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");

        // try {
        //     const Response = await axios({
        //         method: "GET",
        //         url: `${PARTNER_URL}/` + Partner_id + "/edit",
        //         headers: {
        //             Authorization: token
        //         }
        //     });

        //     // if (Response && Response.data) {
        //     // Successful response
        //     console.log("Response====>", Response.data.partner.attachments);
        //     setStafftVal(Response.data.partner.first_name);
        //     setStafftVal1(Response.data.partner.last_name);
        //     setStafftVal2(Response.data.partner.company_name);
        //     setStafftVal3(Response.data.partner.mobile);
        //     setStafftVal4(Response.data.partner.email);
        //     setSelectedOption1(Response.data.partner.state.id);
        //     setStafftVal5(Response.data.partner.fedid_ssn);
        //     setStafftVal6(Response.data.partner.address);
        //     setStafftVal7(Response.data.partner.city);
        //     setStafftVal8(Response.data.partner.notes);

        //     setdocumentarray(Response.data.partner.attachments)

        //     const array = Response.data.states.map((e) => ({
        //         label: e.name,
        //         value: e.id
        //     }));

        //     console.log("arr", array)
        //     setoptions(array);


        // } catch (err) {
        //     console.error("Error while fetching data:", err);
        // }


        var data = {
            "method": "get",
            "route_name": `partners/` + Partner_id + "/edit",


        }
        const Response = await ApiCall(data,dispatch)
        if (Response.success) {

            console.log("Response", Response.success)
            setStafftVal(Response.success.partner.first_name);
            setStafftVal1(Response.success.partner.last_name);
            setStafftVal2(Response.success.partner.company_name);
            setStafftVal3(Response.success.partner.mobile);
            setStafftVal4(Response.success.partner.email);
            setSelectedOption1(Response.success.partner.state.id);
            setStafftVal5(Response.success.partner.fedid_ssn);
            setStafftVal6(Response.success.partner.address);
            setStafftVal7(Response.success.partner.city);
            setStafftVal8(Response.success.partner.notes);

            setdocumentarray(Response.success.partner.attachments)

            const array = Response.success.states.map((e) => ({
                label: e.name,
                value: e.id
            }));

            console.log("arr", array)
            setoptions(array);

        }
        else {
            console.log("Error", Response.error.errors)

        }







    };



    const handleStaff = (event) => {

        setStafftVal(event.target.value);

        // console.log(event);


    };
    const handleStaff1 = (event) => {

        setStafftVal1(event.target.value);
    };
    const handleStaff2 = (event) => {

        setStafftVal2(event.target.value);
    };
    const handleStaff3 = (event) => {

        setStafftVal3(event.target.value);
    };
    const handleStaff4 = (event) => {

        setStafftVal4(event.target.value);
    };
    const handleStaff5 = (event) => {

        setStafftVal5(event.target.value);
    };

    const handleStaff6 = (event) => {

        setStafftVal6(event.target.value);
    };

    const handleStaff7 = (event) => {

        setStafftVal7(event.target.value);
    };

    const handleStaff8 = (event) => {

        setStafftVal8(event.target.value);
    };

    const handleSubmit = async () => {



        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": StaffVal,
            "last_name": StaffVal1,
            "company_name": StaffVal2,
            "mobile": StaffVal3,
            "email": StaffVal4,
            "fedid_ssn": StaffVal5,
            "address": StaffVal6,
            "city": StaffVal7,
            "state_id": selectedOption1,
            "notes": StaffVal8,
            "_method": "",


        }


        // try {
        //     const Response = await axios({
        //         method: "PUT",
        //         url: `${PARTNER_URL}/` + Partner_id,
        //         data: body,
        //         headers: {
        //             Authorization: token
        //         }
        //     })


        //     console.log("Response====>", Response.data)

        //     swal("Success!", "Partner Updated Successfully!", "success")

        //     setTimeout(() => {
        //         navigate("/partners")

        //         swal.close();
        //     }, 1500);

        // }

        // catch (err) {
        //     console.log("errr====>", err.response.data.errors)
        //     setError(err.response.data.errors)
        // }


        var formdata = new FormData();

        formdata.append("first_name", StaffVal);
        formdata.append("last_name", StaffVal1);
        formdata.append("company_name", StaffVal2);
        formdata.append("mobile", StaffVal3);
        formdata.append("email", StaffVal4);
        formdata.append("notes", StaffVal8);
        formdata.append("fedid_ssn", StaffVal5);
        formdata.append("address", StaffVal6);
        formdata.append("city", StaffVal7);
        formdata.append("state_id", selectedOption1);
        formdata.append("_method", "PUT");

        // formdata.append("attachments", (documentarray));

        for (var i = 0; i < documentarray.length; i++) {
            formdata.append(`attachments[${i}][name]`, documentarray[i].name);
            // formdata.append(`attachments[${i}][file]`, documentarray[i].file)
            formdata.append(`attachments[${i}][id]`, documentarray[i].id)

            if (typeof (documentarray[i].file) == "object") {
                formdata.append(`attachments[${i}][file]`, documentarray[i].file)
            }
            // else {
            //     console.log("typeof (documentarray[i].file)", documentarray[i].file)


            // }




        }

        console.log("documentarray", documentarray)
        console.log("formdata====>", (documentarray))

        var data = {
            "method": "post",
            "route_name": `partners/` + Partner_id,
            "body": formdata


        }
        const Response = await ApiCall(data,dispatch)
        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Partner Updated Successfully!", "success")

            setTimeout(() => {
                navigate("/partners")

                swal.close();
            }, 1500);

        }


        else {
            console.log("Error", Response.error.errors)
            setError(Response.error.errors)
        }



    }

    const AddFuc = () => {

        var array = {
            "id": "",
            "name": '',
            "file": ""
        }
        var data = [...documentarray]
        data.push(array)

        setdocumentarray(data)
    }

    const RemoveFuc = (index) => {


        var data = [...documentarray]
        data.splice(index, 1)

        setdocumentarray(data)
    }


    const DoconChange = (e, index, type) => {



        var data = [...documentarray]
        if (type == "name") {
            data[index].name = e.target.value
        }
        else {
            data[index].file = e.target.files[0]

        }
        setdocumentarray(data)


    }


    return (
        <div className="dashboard d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Partner Management</h4>
                        </div>

                        <div className="container-fluid">
                            <div className="row borderbox">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ" >Edit Partner</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/partners" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>
                                <div className="col-sm-12 col-md-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">First Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={StaffVal}
                                                onChange={handleStaff} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Last Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={StaffVal1}
                                                onChange={handleStaff1} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Company Name <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={StaffVal2}
                                                onChange={handleStaff2} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.company_name}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Phone No <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]]" value={StaffVal3}
                                                onChange={handleStaff3} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.mobile}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control validate[required,custom[phone]] " value={StaffVal4}
                                                onChange={handleStaff4} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                        </div>
                                    </div>



                                </div>


                                <div className="col-md-6" style={{ marginTop: '18px' }}>


                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">FEDID/SSN</label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" value={StaffVal5} onChange={handleStaff5} />
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Address</label>
                                        <div className="col-sm-7">
                                            <textarea className="form-control inputbox-height validate[required] note-style" value={StaffVal6}
                                                onChange={handleStaff6} ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">City <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" className="form-control inputbox-height validate[required]" value={StaffVal7} onChange={handleStaff7} />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.city}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="inputPassword3" className="col-sm-3 control-label">State <span className="required">*</span></label>
                                        <div className="col-sm-7">
                                            <div className="">
                                                <Select
                                                    placeholder="[-Select State-]"
                                                    value={Soptions.find(option => option.value === selectedOption1)}
                                                    onChange={(e) => onchangefun(e)}
                                                    options={Soptions}
                                                />
                                            </div>
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.state_id}</span>
                                        </div>
                                    </div>


                                </div>


                                <div className="col-sm-12">
                                    <div className="form-group form-textSetting">
                                        <label for="inputEmail3" class="col-md-2 control-label ">Notes </label>
                                        <div className="col-sm-8">
                                            <textarea className="form-control inputbox-height validate[required] note-style" value={StaffVal8}
                                                onChange={handleStaff8} ></textarea>

                                        </div>
                                    </div>

                                </div>



                                {documentarray.map((e, i) => (
                                    <div style={{ marginTop: '18px', marginBottom: "-21px" }} className="form-textSetting" >


                                        <div className="col-sm-6 col-lg-3 ">
                                            <label for="" className="col-sm-10 control-label">Doucument Name <span className="required">*</span></label>
                                            <div className="col-sm-12 col-lg-11 ">
                                                <input value={e.name} onChange={(e) => DoconChange(e, i, "name")} type="text" className="form-control validate[required,custom[phone]]"
                                                    required />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error[`attachments.${i}.name`]}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3">
                                            <label for="" className="col-sm-8 col-lg-9  control-label">Attachement <span className="required">*</span></label>
                                            <div className="col-sm-12">
                                                <input onChange={(e) => DoconChange(e, i, "file")} type="file" className="form-control validate[required,custom[phone]]"
                                                    required />
                                                <h6 >{e.file ? `document_${i + 1}.pdf` : ""}</h6>

                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error[`attachments.${i}.file`]}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">

                                            <button disabled={documentarray.length > 1 ? false : true} onClick={() => RemoveFuc(i)} type="button" className="btn btn-default minus-btn"><i className="fas fa-minus arrowkey"></i></button>

                                        </div>
                                    </div>
                                ))}
                                <div className="form-group">
                                    <div className="plus-btnstyle">
                                        <button onClick={() => AddFuc()} type="button" className="btn btn-default plus-btn"><i className="fas fa-plus arrowkey"></i></button>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                    </div>
                                </div>

                            </div>


                        </div>




























                    </div>
                </div>
            </div>







        </div >

    );
}
