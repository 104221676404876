import React, { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes, } from "react-router-dom";
import { ProfileSettings } from "./pages/Settings/ProfileSettings";
import { EmailList } from "./pages/SuperAdmin/EmailMgmt/EmailList";
import { UsersList } from "./pages/SuperAdmin/AdminMgmt/UsersList";
import { OraganizationList, } from "./pages/SuperAdmin/OrganizationMgmt/OraganizationList";
import { OrganizationAdd } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationAdd";
import { UserAdd } from "./pages/SuperAdmin/AdminMgmt/UserAdd";
import { Email_Edit } from "./pages/SuperAdmin/EmailMgmt/Email_Edit";
import { OrganizationView } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationView";
import { UserView } from "./pages/SuperAdmin/AdminMgmt/UserView";
import { UserEdit } from "./pages/SuperAdmin/AdminMgmt/UserEdit";
import { OrganizationEdit } from "./pages/SuperAdmin/OrganizationMgmt/OrganizationEdit";
import { AdminSettings } from "./pages/Ad_Settings";
import { StaffList } from "./pages/Admin/StaffMgmt/StaffList";
import { StaffAdd } from "./pages/Admin/StaffMgmt/StaffAdd";
import { JobType_List } from "./pages/Admin/JobType_Mgmt/JobType_List";
import { JobType_Add } from "./pages/Admin/JobType_Mgmt/JobType_Add";
import { VisaType_List } from "./pages/Admin/VisaType_Mgmt/VisaType_List";
import { VisaType_Add, } from "./pages/Admin/VisaType_Mgmt/VisaType_Add";
import { Jobhistory_List } from "./pages/Admin/Job_History/Jobhistory_List";
import { CandidateHistory } from "./pages/Ad_candidate";
// import { CandidateAdd } from "./pages/Ad_candiadd";
import { JobhistoryEdit } from "./pages/Ad_jobhistoryedit";
import { Managersettings } from "./pages/Mg_settings";
import { PartnerList } from "./pages/AccountManager/PartnerMgmt/PartnerList";
import { PartnerAdd } from "./pages/AccountManager/PartnerMgmt/PartnerAdd";
import { RequirementList } from "./pages/AccountManager/Requirement_Mgmt/RequirementList";
import { RequirementAdd } from "./pages/AccountManager/Requirement_Mgmt/RequirementAdd";
import { ShortList } from "./pages/AccountManager/ShortList_Mgmt/ShortList";
import { AccountCandidate } from "./pages/Ac_candidatemgmt";
import { ReportList } from "./pages/AccountManager/Report_Mgmt/ReportList";
import { HeadSettings } from "./pages/Head_Setting";
import { RecruiterSettings } from "./pages/Recruiter_setting";
import { Head_RequirementList } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_RequirementList";
import { Head_RequirementView } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_RequirementView";

import { Head_MyRequirementList } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_MyRequirementList";
import { Head_Assign_Requirement_List } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_Assign_Requirement_List";
import { RequirementViewHead } from "./pages/Head_reqAss_View";
import { RequirementViewMyView } from "./pages/Head_req_viewmyview";

import { CandidateAdd } from "./pages/Candidate_Mgmt/CandidateAdd";
import { CandidateList } from "./pages/Candidate_Mgmt/CandidateList";
import { CandidateView } from "./pages/Candidate_Mgmt/CandidateView";
import { CandidateEdit } from "./pages/Candidate_Mgmt/CandidateEdit";

import { Recruiter_requirementList } from "./pages/Recruiter/Requirement_Mgmt/Recruiter_requirementList";
import { RecruiterCandiate } from "./pages/Recruiter_reqCandi";
import { RecruiterView } from "./pages/Recruiter_reqMainView";
import { RecruiterMainCandidate } from "./pages/Recruiter_CabdiMain";
import { RecruiterCandidateView } from "./pages/Recruiter_CandiView";
import { RecruiterCandidateEdit } from "./pages/Recruiter_CandiEdit";
import { My_Shortlist } from "./pages/AccountManager/ShortList_Mgmt/My_Shortlist";
import { Partner_Shortlist_View } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist_View";

import { Partner_Shortlist } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist";
import { ShortList_Candidates } from "./pages/AccountManager/ShortList_Mgmt/ShortList_Candidates";
import { AcCandidateView } from "./pages/Ac_CandimgmtView";
import { AcCandimgmtEdit } from "./pages/Ac_CandigmtEdit";

import { StaffEdit } from "./pages/Admin/StaffMgmt/StaffEdit";
// import { AdJobEdit } from "./pages/Ad_jobEdit";
import { JobType_Edit } from "./pages/Admin/JobType_Mgmt/JobType_Edit";

import { VisaType_Edit } from "./pages/Admin/VisaType_Mgmt/VisaType_Edit";
import { PartnerEdit } from "./pages/AccountManager/PartnerMgmt/PartnerEdit";
import { PartnerView } from "./pages/AccountManager/PartnerMgmt/PartnerView";
import { Assign_Requirement_List } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requirement_List";
import { RequirementEdit } from "./pages/AccountManager/Requirement_Mgmt/Requirment_Edit";
import { RequirementView } from "./pages/AccountManager/Requirement_Mgmt/requrement_view";

import { LoginContent } from "./pages/Login/Login";
import { Redirect, Router } from "react-router-dom";
import { StaffView } from "./pages/Admin/StaffMgmt/StaffView";
import { ChangePassword } from "./pages/Settings/ChangePassword";

import Page404 from "./pages/Page404/Page404";
import { Assign_Requrement_Edit } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requrement_Edit";
import { Assign_Requrement_View } from "./pages/AccountManager/Requirement_Mgmt/Assign_Requrement_View";
import { Head_Assign_Requirement_View } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_Assign_Requirement_View";
import { Head_MyRequirement_View } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_MyRequirement_View";

import { Head_SelectCandidate_Add } from "./pages/HeadRecruiter/Requirement_Mgmt/Head_SelectCandidate_Add";
import { Assign_Req_Cand_View } from "./pages/AccountManager/Requirement_Mgmt/Assign_Req_Cand_View";
import { Assign_Req_Cand_Edit } from "./pages/AccountManager/Requirement_Mgmt/Assign_Req_Cand_Edit";
import { Recruiter_requirement_View } from "./pages/Recruiter/Requirement_Mgmt/Recruiter_requirement_View";
import { Partner_Shortlist_Edit } from "./pages/AccountManager/ShortList_Mgmt/Partner_Shortlist_Edit";
import { Jobhistory_View } from "./pages/Admin/Job_History/Jobhistory_View.";
import { Jobhistory_Edit } from "./pages/Admin/Job_History/Jobhistory_Edit";
import { History_Req_Cand_View } from "./pages/Admin/Job_History/History_Req_Cand_View";
import { Recruiter_SelectCandidate_Add } from "./pages/Recruiter/Requirement_Mgmt/Recruiter_SelectCandidate_Add";
import { Shortlist_Req_Cand_View } from "./pages/AccountManager/ShortList_Mgmt/Shortlist_Req_Cand_View";
import { NotificationList } from "./pages/AccountManager/Notification/NotificationList";
import { Candidates } from "./pages/AccountManager/Report_Mgmt/Candidates";




const AllRoutes = () => {

  // const Layout = ({ children }) => (
  //   <div >
  //     {/* Your Spinner element here */}
  //     <Spinner />
  //     {children}
  //   </div>
  // );



  const token = localStorage.getItem("IDMS_TOKEN");



  return (

    // <BrowserRouter>

    <Routes>

      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/profilesettings" element={<ProfileSettings />} />

      <Route path="/notifications" element={<NotificationList />} />

      <Route path="/organizations" element={<OraganizationList />} />
      <Route path="/organizations_add" element={<OrganizationAdd />} />
      <Route path="/organization_edit/:id" element={<OrganizationEdit />} />
      <Route path="/organization_view/:id" element={<OrganizationView />} />

      <Route path="/emailcontents" element={<EmailList />} />
      <Route path="/emailcontents_edit/:id" element={<Email_Edit />} />

      <Route path="/user" element={<UsersList />} />
      <Route path="/user_add" element={<UserAdd />} />
      <Route path="/user_edit/:id" element={<UserEdit />} />
      <Route path="/user_view/:id" element={<UserView />} />

      <Route path="/staffs" element={<StaffList />} />
      <Route path="/staffs_add" element={<StaffAdd />} />
      <Route path="/staffs_edit/:id" element={<StaffEdit />} />
      <Route path="/staffs_view/:id" element={<StaffView />} />

      <Route path="/jobtype" element={<JobType_List />} />
      <Route path="/jobtype_add" element={<JobType_Add />} />
      <Route path="/jobtype_edit/:id" element={<JobType_Edit />} />

      <Route path="/visatype" element={<VisaType_List />} />
      <Route path="/visatype_add" element={<VisaType_Add />} />
      <Route path="/visatype_edit/:id" element={<VisaType_Edit />} />

      <Route path="/jobhistory" element={<Jobhistory_List />} />
      <Route path="/jobhistory_view/:id" element={<Jobhistory_View />} />
      <Route path="/jobhistory_edit/:id" element={<Jobhistory_Edit />} />
      <Route path="/jobhistory_candidates_view/:req_id/:cand_id" element={<History_Req_Cand_View />} />

      <Route path="/partners" element={<PartnerList />} />
      <Route path="/partners_add" element={<PartnerAdd />} />
      <Route path="/partners_edit/:id" element={<PartnerEdit />} />
      <Route path="/partners_view/:id" element={<PartnerView />} />

      <Route path="/requirements" element={<RequirementList />} />
      <Route path="/requirements_add" element={<RequirementAdd />} />
      <Route path="/requirements_edit/:id" element={<RequirementEdit />} />
      <Route path="/requirements_view/:id" element={<RequirementView />} />
      <Route path="/assigned_requirement" element={<Assign_Requirement_List />} />
      <Route path="/assigned_requirement_view/:id" element={<Assign_Requrement_View />} />
      <Route path="/assigned_requirement_edit/:id" element={<Assign_Requrement_Edit />} />
      <Route path="/req_candidates_view/:req_id/:cand_id" element={<Assign_Req_Cand_View />} />
      <Route path="/req_candidates_edit/:req_id/:cand_id" element={<Assign_Req_Cand_Edit />} />

      <Route path="/shortlists" element={<ShortList />} />
      <Route path="/shortlists_candidateslist/:id" element={<ShortList_Candidates />} />
      <Route path="/my_shortlists" element={<My_Shortlist />} />
      <Route path="/partner_shortlists" element={<Partner_Shortlist />} />
      <Route path="/partner_shortlists_view/:req_id/:cand_id" element={<Partner_Shortlist_View />} />
      <Route path="/partner_shortlists_edit/:req_id/:cand_id" element={<Partner_Shortlist_Edit />} />
      <Route path="/shortlists_req_candidates_view/:req_id/:cand_id" element={<Shortlist_Req_Cand_View />} />

      <Route path="/reports" element={<ReportList />} />
      <Route path="/candidates_reports/:req_id/:filter" element={<Candidates />} />
      
      <Route path="/head_requirements" element={<Head_RequirementList />} />
      <Route path="/head_requirements_view/:id" element={<Head_RequirementView />} />
      <Route path="/head_myrequirements_list" element={<Head_MyRequirementList />} />
      <Route path="/head_myrequirement_view/:id" element={<Head_MyRequirement_View />} />
      <Route path="/head_assignrequirements_list" element={<Head_Assign_Requirement_List />} />
      <Route path="/head_assignrequirements_view/:id" element={<Head_Assign_Requirement_View />} />
      <Route path="/head_selectCandidate_add/:id" element={<Head_SelectCandidate_Add />} />

      <Route path="/candidates" element={<CandidateList />} />
      <Route path="/candidates_add" element={<CandidateAdd />} />
      <Route path="/candidates_view/:id" element={<CandidateView />} />
      <Route path="/candidates_edit/:id" element={<CandidateEdit />} />

      <Route path="/recruiter_requirements" element={<Recruiter_requirementList />} />
      <Route path="/recruiter_requirements_view/:id" element={<Recruiter_requirement_View />} />
      <Route path="/recruiter_selectcandidate_add/:id" element={<Recruiter_SelectCandidate_Add />} />


      <Route path="*" element={<Navigate to="404" />} />


    </Routes >

    // </BrowserRouter>
  );
};

export default AllRoutes;
