import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";

import '../../Profile.css';
import Select from 'react-select';
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import axios from "axios";
import { saveAs } from 'file-saver';
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { TableHeader } from "../../../Components/TableHeader";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";
// const options1 = [

//     { value: 'Krish', label: 'Krish' },

// ];
// const options2 = [

//     { value: 'Krish001@gmail', label: 'Krish001@gmail' },

// ];
// const options3 = [

//     { value: '4528761302', label: '4528761302' },

// ];
// const options4 = [

//     { value: 'MCA', label: 'MCA' },

// ];

export const Candidates = () => {

    const [Name, setName] = useState('');
    const [EmailCandidate, setEmailCandidate] = useState('');
    const [ConatctNo, setConatctNo] = useState('');
    const [Qualification, setQualification] = useState('');


    const [NameList, setNameList] = useState([]);
    const [EmailList, setEmailList] = useState([]);
    const [ConatctList, setConatctList] = useState([]);
    const [QualificationList, setQualificationList] = useState([]);


    const type = localStorage.getItem("user_type")
    const [candidateslist, setCandidateslist] = useState([]);
    const navigate = useNavigate()

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: '',


    });
    const { dispatch } = useApiResponseContext();
    const { apiState } = useApiResponseContext();

    const { req_id, filter } = useParams();

    // alert(req_id)
    useEffect(() => {


        GetApi(query)

    }, [])

    const GetApi = async (query, fill) => {



        var data = {
            "method": "GET",
            "route_name": "candidates?",
            'query': query,
            "cand_filter": {
                candidates_selected: filter == "candidates_selected" ? true : false,
                candidates_rejected: filter == "candidates_rejected" ? true : false,
                candidates_accepted: filter == "candidates_accepted" ? true : false,
                requirement_id: req_id ? req_id : "",
                first_name: "",
                email: "",
                mobile: "",
                qualification: ""

            }

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            // var array = Response.data.first_names.map((e) => e)

            // console.log("Response.data.first_names", Response.success.first_names)

            var array = Response.success.first_names.map(function (name, index) {
                return {
                    "label": name,
                    "value": index + 1,

                };
            });

            // console.log("array", array)
            setNameList(array)
            var array1 = Response.success.emails.map(function (name, index) {
                return {
                    "label": name,
                    "value": index + 1,

                };
            })

            setEmailList(array1)
            var array2 = Response.success.mobiles.map(function (name, index) {
                return {
                    "label": name,
                    "value": index + 1,

                };
            })


            setConatctList(array2)
            var array3 = Response.success.qualifications.map(function (name, index) {
                return {
                    "label": name,
                    "value": index + 1,

                };
            })


            setQualificationList(array3)


            console.log("Response", Response.success.candidates)
            setCandidateslist(Response.success.candidates.data)

            dispatch({ type: 'SUCCESS', payload: Response.success.candidates });

        }
        else {
            console.log("Error", Response.error)
            dispatch({ type: 'ERROR', payload: Response.error });


        }

    }



    const EditFunc = async (id) => {
        console.log("dfdf", id)


        navigate(`/candidates_edit?id=${id}`);

    }

    const PdfDownload = async (id) => {

        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/resume',
            "res_type": 'blob'
        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }

    }

    const DeleteFun = async (id) => {

        console.log("id", id)


        swal({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            buttons: {
                cancel: 'No',
                confirm: 'Yes',
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                // User clicked "Yes", perform the delete action
                // Add your delete logic here

                var data = {
                    "method": "DELETE",
                    "route_name": "candidates/" + id,

                }

                const Response = await ApiCall(data, dispatch)

                if (Response.success) {

                    console.log("Response", Response.success)
                    swal("Success!", "Candidate Deleted Successfully!", "success")
                    GetApi(query)
                    setTimeout(() => {
                        swal.close();
                    }, 1500);
                }
                else {
                    console.log("Error", Response.error)

                }



                // swal('Poof! Your file has been deleted!', {
                //     icon: 'success',
                // });
            } else {
                // User clicked "No" or closed the dialog
                swal('Your file is safe!', {
                    icon: 'info',
                });
            }
        });



    }

    const PageOnpresFunc = (pagedrop, value,) => {
        var cand_filter = {
            first_name: Name ? Name.label : "",
            email: EmailCandidate ? EmailCandidate.label : "",
            mobile: ConatctNo ? ConatctNo.label : "",
            qualification: Qualification ? Qualification.label : ""
        }


        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: query.sort_by,
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        GetApi(post_data, cand_filter);

    }


    const OnSearchFunc = (value) => {
        var cand_filter = {

            first_name: Name ? Name.label : "",
            email: EmailCandidate ? EmailCandidate.label : "",
            mobile: ConatctNo ? ConatctNo.label : "",
            qualification: Qualification ? Qualification.label : ""
        }

        console.log("Name", Name)

        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        setQuery(post_data)
        GetApi(post_data, cand_filter);

    }


    const Filter = (e, type) => {

        console.log("typetypetypetype", type)

        var normal_query = {
            per_page: 10,
            page: 1,
            search: '',
            sort_by: "",
            sort_direction: '',
        }

        switch (type) {
            case "Name":
                var cand_filter = {

                    first_name: e.label,
                    email: EmailCandidate ? EmailCandidate.label : "",
                    mobile: ConatctNo ? ConatctNo.label : "",
                    qualification: Qualification ? Qualification.label : ""
                }
                setName(e)

                GetApi(query, cand_filter)
                return

            case "Email":
                var cand_filter = {

                    first_name: Name ? Name.label : "",
                    email: e.label,
                    mobile: ConatctNo ? ConatctNo.label : "",
                    qualification: Qualification ? Qualification.label : ""
                }
                setEmailCandidate(e)

                GetApi(query, cand_filter)
                return
            case "Mobile":
                var cand_filter = {

                    first_name: Name ? Name.label : "",
                    email: EmailCandidate ? EmailCandidate.label : "",
                    mobile: e.label,
                    qualification: Qualification ? Qualification.label : ""
                }
                setConatctNo(e)

                GetApi(query, cand_filter)
                return
            case "Qualification":
                var cand_filter = {

                    first_name: Name ? Name.label : "",
                    email: EmailCandidate ? EmailCandidate.label : "",
                    mobile: ConatctNo ? ConatctNo.label : "",
                    qualification: e.label,
                }
                setQualification(e)

                GetApi(query, cand_filter)
                return

            case "clear":
                setName("")
                setConatctNo("")
                setQualification("")
                setEmailCandidate("")
                GetApi(query)


                return


            // default:

            //     return
        }
    }



    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Name",
            icon: "Yes",
            key: "first_name",
            sort: ""
        },
        {
            name: "No.Requirement",
            icon: "Yes",
            key: "requirements_count",
            sort: ""
        },

        {
            name: "Contact No.",
        },
        {
            name: "Qualification",
        },
        {
            name: "Resume",

        },

        {
            name: "Status",
            icon: "Yes",
            key: "status.name",
            sort: ""

        },
        {
            name: "Action",
        },

    ]

    const TableHeadings1 = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Name",
            icon: "Yes",
            key: "first_name",
            sort: ""
        },

        {
            name: "Contact No.",
        },
        {
            name: "Qualification",
        },
        {
            name: "Status",
            icon: "Yes",
            key: "status.name",
            sort: ""
        },

        {
            name: "Resume",

        },

        {
            name: "Action",
        },

    ]
    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        GetApi(post_data);

    }

    console.log("Name", Name)



    const statusChangeFunc = async (visastype_id, index) => {


        var data = {
            "method": "POST",
            "route_name": "candidates/" + visastype_id + "/status"

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...candidateslist]
            array[index].status = (array[index].status != "Active" ? "Active" : 'In active')
            setCandidateslist(array)


            swal("Success!", "Candidate Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }
    }




    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Candidate Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">{filter == "candidates_selected" ? "Selected" : filter == "candidates_rejected" ? "Rejected" : filter == "candidates_accepted" ? "Accepted" : ""}    Candidate List</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn"
                                        //  to="/candidates" 
                                        onClick={() => navigate(-1)}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>




                                {/* <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />


                                <div className="row " style={{ padding: 10 }}>


                                    <div className="col-md-2 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Name</label>
                                            <div className="col-sm-12">

                                                <div className="">
                                                    <Select
                                                        placeholder="Select Name"
                                                        value={Name ? NameList.find(option => option.value === Name.value) : null}
                                                        onChange={(date) => Filter(date, "Name")}
                                                        options={NameList}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-2">

                                        <div className="" >
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Email</label>
                                            <div className="col-sm-12">

                                                <div className="">
                                                    <Select
                                                        placeholder="Select Email"
                                                        value={EmailCandidate}
                                                        onChange={(date) => Filter(date, "Email")}
                                                        options={EmailList}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-2 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Contact.No</label>
                                            <div className="col-sm-12">

                                                <div className="">
                                                    <Select
                                                        placeholder="Contact no"
                                                        value={ConatctNo}
                                                        onChange={(date) => Filter(date, "Mobile")}
                                                        options={ConatctList}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-3">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Qualification</label>
                                            <div className="col-sm-12 ">

                                                <div className="">
                                                    <Select
                                                        placeholder="Select Qualification"
                                                        value={Qualification}
                                                        onChange={(date) => Filter(date, "Qualification")}
                                                        options={QualificationList}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    {Name || EmailCandidate || Qualification || ConatctNo ?
                                        <div style={{ display: "flex", alignItems: "end" }} className="col-md-2">
                                            <button onClick={() => Filter("e", "clear")} className="clean-text">
                                                Clear
                                            </button>

                                        </div>
                                        : null}
                                </div> */}



                                <div className="table-colorOrgan mob-overflow" >
                                    <table>

                                        <TableHeader Titles={TableHeadings1} Sortfunc={(key, direct) => onSorting(key, direct)} />


                                        {candidateslist.length > 0 ?
                                            <tbody>
                                                {candidateslist.map((e, i) => (
                                                    <tr>
                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                        }

                                                        <td > <Link to={`/candidates_view/${e.id}`}  > {e.name}</Link></td>
                                                        {/* <td>( {e.requirements_count} )</td> */}
                                                        <td>{e.mobile}</td>
                                                        <td>{e.qualification}</td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    e.status === 'Active'
                                                                        ? 'green-status'
                                                                        : e.status === 'In active'
                                                                            ? 'red-status'
                                                                            : e.status === 'Pending'
                                                                                ? 'yellow-status'
                                                                                : e.status === 'Trash'
                                                                                && 'red-status'
                                                                }
                                                            >
                                                                {e.status}
                                                            </span>
                                                        </td>
                                                        {/* <td>
                                                            {e.status === 'Trash' ?
                                                                <p style={{ marginLeft: 40 }}>-</p>
                                                                :
                                                                <Link onClick={() => statusChangeFunc(e.id, i)}  >{e.status === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                            }
                                                        </td> */}
                                                        <td >

                                                            {/* <a href={e.resume}
                                                            download="Example-PDF-document"
                                                            target="_blank"
                                                            rel="noopener noreferrer"   ><i class="fa fa-download"></i> Download</a> */}
                                                            <Link onClick={() => PdfDownload(e.id)}>
                                                                <i className="fas fa-download"> </i>
                                                                Download
                                                            </Link>

                                                        </td>
                                                        {/* {type == "Admin" &&
                                                            <td>{e.status}</td>
                                                        } */}
                                                        <td>
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    <Link to={`/candidates_view/${e.id}`}  > View</Link>
                                                                    {type != "Admin" ?
                                                                        <Link to={`/candidates_edit/${e.id}`}> Edit</Link>
                                                                        : null}
                                                                    {type != "Admin" ?

                                                                        <Link onClick={() => DeleteFun(e.id)}> Delete</Link>
                                                                        : null}


                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                ))
                                                }





                                            </tbody>
                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>





















                    </div>
                </div>
            </div>
        </div>










    );
}

