import React, { useEffect, useRef, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select, { useStateManager } from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { ApiCall } from "../../../Config/API";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import BarChart from "../../../Components/BarChart";
import Chart from 'chart.js/auto';
import { TableHeader } from "../../../Components/TableHeader";

// import { Bar } from "react-chartjs-2";

// import { BarChart } from "@mui/x-charts";
// import { Bar } from 'react-chartjs-2';
export const ReportList = () => {



    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });


    useEffect(() => {

        getAll(query);
        RevenuereportApi()

    }, []);

    const [fromdate, setFromdate] = useState('');
    const [todate, setTodate] = useState('');
    const [searchtrue, setSearchtrue] = useState(false);

    const [requirement, setRequirement] = useState(true);
    const [revenue, setrevenue] = useState(false);

    const [amountvalues, setAmountvalues] = useState('');

    const [reportlist, setReportlist] = useState([]);

    const [total_count, setTotal_count] = useState(0);

    const { apiState } = useApiResponseContext();

    const { dispatch } = useApiResponseContext();

    const getAll = async (query, fromdate, todate) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `requirements/reports?`,
            'query': query,
            "extra_date": {

                "from_date": (fromdate ? moment(fromdate).format('MM-DD-YYYY') : ""),
                "to_date": (todate ? moment(todate).format('MM-DD-YYYY') : "")
            }

        }
        const Response = await ApiCall(data, dispatch)
        if (Response.success) {

            console.log("Response", Response.success)
            setReportlist(Response.success.data)

            setTotal_count(Response.success.total)

            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: query.sort_by,
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }



    const handleDateChange = (date) => {
        setFromdate(date);
    };




    const handleDateChange1 = (date) => {
        setTodate(date);
    };

    const SearchButton = () => {
        setSearchtrue(true)
        getAll(query, fromdate, todate)
    };


    const ClearButton = () => {
        setSearchtrue(false)
        setFromdate("")
        setTodate("")
        getAll(query)
    };


    const [data, setData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First Dataset',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: amountvalues,
            },
        ],
    });








    const RevenuereportApi = async (query) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `revenues/reports`,



        }
        const Response = await ApiCall(data, dispatch)
        if (Response.success) {

            console.log("Response venuee", Response.success)
            const amountArray = Response.success.map(item => item.amount);
            setAmountvalues(amountArray)
            console.log(amountArray);

        }
        else {
            console.log("Error", Response.error.errors)

        }

    };
    const RevenueContent = () => {


        const chartRef = useRef(null);

        useEffect(() => {
            const ctx = chartRef.current.getContext('2d');
            const myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'Monthly Sales',
                            data: amountvalues,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            type: 'linear',
                            position: 'left',
                            beginAtZero: true,
                        },
                        x: {
                            type: 'category',
                            position: 'bottom',
                        },
                    },
                },
            });

            return () => {
                // Destroy the chart instance on component unmount
                myChart.destroy();
            };
        }, []);

        return <canvas className='barchart-style' ref={chartRef} />;




    }
    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);

    }
    <tr>
        <th className="color"> S.No <i className="fas fa-sort padding_sort"></i></th>
        <th className="color">Requirement No.</th>
        <th className="color">No. of Candidate</th>
        <th className="color">No. of Candidate Selected</th>
        <th className="color">No. of Candidate Accepted</th>
        <th className="color">No. of Candidate Rejected</th>

    </tr>


    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },

        {
            name: "Requirement No.",
            icon: "Yes",
            key: "requirement_no",
            sort: ""
        },
        {
            name: "No. of Candidate",
            icon: "Yes",
            key: "candidates_count",
            sort: ""
        },
        {
            name: "No. of Candidate Selected",
            icon: "Yes",
            key: "candidates_selected_count",
            sort: ""
        },
        {
            name: "No. of Candidate Accepted",
            icon: "Yes",
            key: "candidates_accepted_count",
            sort: ""
        },
        {
            name: "No. of Candidate Rejected",
            icon: "Yes",
            key: "candidates_rejected_count",
            sort: ""

        },

    ]

    const CandidateNavigate = (id, name) => {



    }


    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Report Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">


                                </header>


                                <div className="bg-color" >
                                    <div>

                                        <Link onClick={() => (setRequirement(true), setrevenue(false))} className={requirement ? "report-color1_hover" : "report-color1"}>
                                            <span style={{ fontSize: 14, fontFamily: "system-ui" }}>
                                                Requirement Report
                                            </span>
                                        </Link>

                                        <Link onClick={() => (setrevenue(true), setRequirement(false))} className={revenue ? "report-color1_hover" : "report-color1"}>
                                            <span style={{ fontSize: 14, fontFamily: "system-ui" }}>
                                                Revenue Report
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                                {requirement ?
                                    <div>
                                        <div className="report-date mob-reportfull">
                                            <div className="mob-inputreport">
                                                <label for="" className="report-dateheading">From Date</label>
                                                {/* <input type="date" className="report-inputdate" required /> */}

                                                <DatePicker
                                                    selected={fromdate}
                                                    className="report-inputdate"
                                                    onChange={handleDateChange}
                                                    dateFormat="MM-dd-yyyy"
                                                    placeholderText="MM-DD-YYYY"

                                                />


                                            </div>
                                            <div className="mob-inputreport">
                                                <label for="" className="report-dateheading">To Date</label>
                                                <DatePicker
                                                    selected={todate}
                                                    onChange={handleDateChange1}
                                                    dateFormat="MM-dd-yyyy"
                                                    className="report-inputdate"
                                                    placeholderText="MM-DD-YYYY"

                                                />
                                            </div>
                                            <div style={{ marginTop: 28 }} className="mob-inputreport">
                                                <button onClick={() => SearchButton()} className="report-search"><i className="fa fa-search arrowkey"></i>
                                                    Search</button>
                                            </div>
                                            {fromdate && todate && searchtrue &&
                                                <div style={{ marginTop: 28 }} className="mob-inputreport">
                                                    <button onClick={() => ClearButton()} className="cancel-search"><i class="fa fa-times" ></i>
                                                        Cancel</button>
                                                </div>
                                            }
                                        </div>

                                        {/* <div className="row">
                                    <div className="col-lg-12" >
                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-5 col-lg-5 control-label">From Date</label>
                                            <div className="col-sm-8">
                                                <input type="date" className="form-control validate[required,custom[phone]]" required  />
                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 col-lg-4 control-label">To Date</label>
                                            <div className="col-sm-8">
                                                <input type="date" className="form-control validate[required,custom[phone]]" required  />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                        <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />



                                        <div className="table-colorOrgan mob-overflow" >
                                            <table>
                                                {/* <thead >
                                                    <tr>
                                                        <th className="color"> S.No <i className="fas fa-sort padding_sort"></i></th>
                                                        <th className="color">Requirement No.</th>
                                                        <th className="color">No. of Candidate</th>
                                                        <th className="color">No. of Candidate Selected</th>
                                                        <th className="color">No. of Candidate Accepted</th>
                                                        <th className="color">No. of Candidate Rejected</th>

                                                    </tr>
                                                </thead> */}

                                                <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />

                                                {reportlist.length > 0 ?
                                                    <tbody>
                                                        {reportlist.map((e, i) => (

                                                            <tr>
                                                                {query.sort_direction == "asc" && query.sort_by == "" ?
                                                                    <td className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                                    :
                                                                    <td className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                                }
                                                                <td>  <Link to={`/requirements_view/${e.id}`}  > {e.requirement_no}</Link></td>
                                                                <td ><Link to={`/candidates_reports/${e.id}/candidates`}  >( {e.candidates_count} )</Link>  </td>
                                                                <td ><Link to={`/candidates_reports/${e.id}/candidates_selected`}> ( {e.candidates_selected_count} )</Link> </td>
                                                                <td><Link to={`/candidates_reports/${e.id}/candidates_accepted`}> ( {e.candidates_accepted_count} )</Link></td>

                                                                <td><Link to={`/candidates_reports/${e.id}/candidates_rejected`}> ( {e.candidates_rejected_count} )</Link></td>



                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :
                                                    <tbody>

                                                        <tr style={{ textAlign: 'center' }}>
                                                            <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                        </tr>
                                                    </tbody>

                                                }


                                            </table>
                                        </div>

                                        <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />

                                    </div>
                                    :
                                    <RevenueContent />


                                }

                            </section>

                        </div>





                    </div>
                </div>
            </div>

        </div>










    );
}

