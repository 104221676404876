import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { TableHeader } from "../../../Components/TableHeader";
// import Search from 'react-select';

const recrutieroptions = [

    { value: 'Paul', label: 'Paul' },
    { value: 'Flores', label: 'Flores' },

];


export const Head_Assign_Requirement_List = () => {



    const [recrutierAss, setrecrutierAss] = useState(null);

    const [RequirementList, setRequirementList] = useState([]);

    const [RecruiterList, setRecruiterList] = useState([]);
    const [checkbox, setCheckbox] = useState(false);
    const [Assignnedlist, setAssignnedlist] = useState([]);


    const [requirement_id, setRequirement_id] = useState('');
    const [notesmodal, setNotesmodal] = useState(false);

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: ''

    });

    useEffect(() => {


        GetApi(query);
    }, []);

    const { dispatch } = useApiResponseContext();
    const { apiState } = useApiResponseContext();

    const GetApi = async (query) => {
        var data = {
            "method": "get",
            "route_name": "requirement_assigns?",
            "query": query

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            dispatch({ type: 'SUCCESS', payload: Response.success.requirements });

            console.log("Response", Response.success)

            setRequirementList(Response.success.requirements.data)

            var array = Response.success.recruiters.map((e) => ({

                id: e.id,
                label: e.name,
                selected: false
            }))



            setRecruiterList(array)


        }
        else {
            console.log("Error", Response.error)

        }
    };

    const selectOption = async (id, e) => {

        const data = [...Assignnedlist]

        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = Assignnedlist.filter(item => item !== id);
            // console.log(ssidArray);
            setAssignnedlist(ssidArray)

        } else {
            data.push(id)

            // console.log("data", data)

            setAssignnedlist(data)


        }



    }

    const AssignRecruiter = async () => {

        // console.log("recrutierAss", recrutierAss.id)
        // console.log("Assignnedlist", Assignnedlist)

        const bodydata = {
            "recruiter_ids": [recrutierAss.id],
            "requirement_ids": Assignnedlist
        }



        var data = {
            "method": "POST",
            "route_name": "requirement_assigns",
            'body': bodydata
        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Your candidates has been assigned successfully.", "success")
            GetApi(query)
            setAssignnedlist([])
            setrecrutierAss(null)
            setCheckbox(true)
            setTimeout(() => {
                swal.close();
                setCheckbox(false)

            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }


    }



    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        GetApi(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        GetApi(post_data);

    }


    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },

        {
            name: "Requirement No.",
            icon: "Yes",
            key: "requirement_no",
            sort: ""
        },
        {
            name: "Requirement Title",
            icon: "Yes",
            key: "title",
            sort: ""
        },
        {
            name: "Location",
        },
        {
            name: "Recruiter",
        },


        {
            name: "No.Candidate",
            icon: "Yes",
            key: "candidates_count",
            sort: ""
        },
        {
            name: "Active Status",

        },
        {
            name: "Action",
            notes: "no"
        },

        {
            name: "Assign",
        },
        {
            name: "Notes",
        },
    ]

    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        GetApi(post_data);

    }

    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Assigned Requirement</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/head_requirements" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                    </div>
                                </header>


                                <div style={{ margin: '15px' }}>
                                    {/* <div className="row"> */}
                                    <div className=" form-textSetting">
                                        <label for="inputPassword3" className="col-sm-1.5 control-label">Assign to Recruiter</label>
                                        <div className="col-sm-3 custom-124">



                                            <div className="custom-dropdown">
                                                {/* <div className="req-style">
                                                    <button style={{ border: "none", backgroundColor: "#315189", color: "white" }}>Select </button>
                                                    <i className="fas fa-sort-down padding_sort"></i>
                                                </div> */}
                                                <div >
                                                    <Select
                                                        placeholder="[-Select Recruiter-]"
                                                        // defaultValue={Headreq}
                                                        onChange={setrecrutierAss}
                                                        value={recrutierAss ? RecruiterList.find(option => option.value === 22) : null}
                                                        options={RecruiterList}
                                                    />
                                                </div>
                                            </div>


                                        </div>


                                        {/* <div className="col-md-3">
                                            <label for="inputPassword3" className="col-sm-8 control-label">Assign to Recruiter</label>
                                        </div>
                                        <div className="col-sm-3">

                                            <div className="">
                                                <Select
                                                    placeholder="[-Select Recruiter-]"
                                                    // defaultValue={Headreq}
                                                    onChange={setrecrutierAss}
                                                    value={recrutierAss ? RecruiterList.find(option => option.value === 22) : null}
                                                    options={RecruiterList}
                                                />
                                            </div>

                                        </div> */}
                                        {recrutierAss && Assignnedlist.length > 0 ?
                                            <div onClick={() => AssignRecruiter()} className=" btn btn-default assign_addbtn  col-sm-1"  >Assign</div>
                                            : null}
                                    </div>
                                </div>

                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />





                                <div className="table-colorOrgan mob-overflow">
                                    <table >

                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />

                                        {/* <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">Location</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Recruiter</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Candidate</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Action</th>
                                                <th className="color">Assign</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead> */}
                                        {RequirementList.length > 0 ?
                                            <tbody>
                                                {RequirementList.map((e, i) => (
                                                    <tr className="tabledata">
                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                        }
                                                        <td ><Link to={`/head_assignrequirements_view/${e.id}`}  > {e.requirement_no}</Link> </td>
                                                        <td>{e.title}</td>
                                                        <td>{e.location}</td>
                                                        <td style={{ width: 150 }}>{e.recruiters}</td>
                                                    <td style={{textAlign:"center"}}>({e.candidates_count})</td>
                                                        <td style={{ width: 110 }}>
                                                            <span
                                                             style={{ marginLeft: 10 }}
                                                                className={
                                                                    e.active_status.name === 'Active'
                                                                        ? 'green-status'
                                                                        : e.active_status.name === 'In active'
                                                                            ? 'red-status'
                                                                            : e.active_status.name === 'Pending'
                                                                                ? 'yellow-status'
                                                                                : e.active_status.name === 'Trash'
                                                                                && 'red-status'
                                                                }
                                                            >
                                                                {e.active_status.name}
                                                            </span>
                                                        </td>


                                                        <td>
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    {/* <a href="/RequirementViewHead">View</a> */}
                                                                    <Link to={`/head_assignrequirements_view/${e.id}`}  > View</Link>


                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {/* <input type="checkbox" id="checkbox" /> */}
                                                            <input disabled={e.active_status.name === 'Active' ? false : true} checked={checkbox ? false : undefined} onChange={() => selectOption(e.id)} type="checkbox" />

                                                        </td>
                                                        <td>
                                                            {/* <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' /> */}

                                                            <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                                <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                            </Link>
                                                        </td>
                                                    </tr>

                                                ))}

                                            </tbody>
                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>



                        {notesmodal ?
                            <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                            : null}


















                    </div>
                </div>
            </div>
        </div>










    );
}

