import React, { useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import AllRoutes from "../AllRoutes";
import { Spinner } from "./Spinner";

// import { useApiResponseContext } from "../Config/API/LoaderReducer";
import { useApiResponseContext } from "../Config/API/ApiResponseContext";




export const MainScreen = () => {

    const [loader, setLoader] = useState(false)

    const { apiState } = useApiResponseContext();

    // console.log("responseState", apiState)
    return (

        <div className="dashboard d-flex">
            <div>
                <Sidebar />
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <Navbar />

                <AllRoutes />
            </div>
        </div >
    )
    




}

