import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link, useNavigate } from 'react-router-dom';


import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { PARTNER_URL } from "../../../constant";
import { useParams } from "react-router-dom";
import moment from "moment";

// Admin page here as hero

export const PartnerView = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const Partner_id = id

    const [stValq, setstValq] = useState('');
    const [stVal1q, setstVal1q] = useState('');
    const [stVal2q, setstVal2q] = useState('');
    const [stVal3q, setstVal3q] = useState('');
    const [stVal4q, setstVal4q] = useState('');
    const [stVal5q, setstVal5q] = useState('');
    const [stVal6q, setstVal6q] = useState('');
    const [stVal7q, setstVal7q] = useState('');
    const [stVal8q, setstVal8q] = useState('');
    const [stVal9q, setstVal9q] = useState('');
    const [stVal10q, setstVal10q] = useState('');
    const [stVal11q, setstVal11q] = useState('');




    useEffect(() => {


        fetchData();
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${PARTNER_URL}/` + Partner_id,
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            setstValq(Response.data.name)
            setstVal1q(Response.data.user_type.type)
            setstVal2q(Response.data.mobile)
            setstVal3q(Response.data.company_name)
            setstVal4q(Response.data.email)
            setstVal5q(Response.data.notes)
            setstVal6q(Response.data.fedid_ssn)
            setstVal7q(Response.data.address)
            setstVal8q(Response.data.city)
            setstVal9q(Response.data.state.name)
            setstVal10q(Response.data.status.name)
            setstVal11q(Response.data.created_at)

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Partner Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Partner</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" onClick={() => navigate(-1)} ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stValq}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal1q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Mobile</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal2q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Company Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal3q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal4q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Note</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal5q}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">FEDID/SSN</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal6q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal7q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">City</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal8q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">State</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal9q}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span className="Active_label">{stVal10q}</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(stVal11q).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div>




                    </div>
                </div>
            </div>
        </div>





    );
}
