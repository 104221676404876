import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { ApiCall } from "../../../Config/API";
import { Link } from "react-router-dom";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import { TableHeader } from "../../../Components/TableHeader";

export const EmailList = () => {



  const [query, setQuery] = useState({
    per_page: 10,
    page: 1,
    search: '',
    sort_by: "",
    sort_direction: 'desc'

  });


  useEffect(() => {

    getAll(query);

  }, []);

  const [emailcontentsList, setEmailcontentsList] = useState([]);

  const [total_count, setTotal_count] = useState(0);

  const { dispatch } = useApiResponseContext();
  const { apiState } = useApiResponseContext();

  const getAll = async (query) => {


    console.log("query", query)
    var data = {
      "method": "get",
      "route_name": `mail_contents?`,
      'query': query

    }
    const Response = await ApiCall(data,dispatch)
    if (Response.success) {

      console.log("Response", Response.success.data)
      setEmailcontentsList(Response.success.data)

      setTotal_count(Response.success.total)

      dispatch({ type: 'SUCCESS', payload: Response.success });

    }
    else {
      console.log("Error", Response.error.errors)
      dispatch({ type: 'ERROR', payload: Response.error });
    }

  };

  const PageOnpresFunc = (pagedrop, value,) => {

    var post_data = {
      per_page: pagedrop,
      page: value,
      search: query.search,
      sort_by: query.sort_by,
      sort_direction: query.sort_direction
    }
    console.log("value", value)

    setQuery(post_data)

    getAll(post_data);

  }


  const OnSearchFunc = (value) => {


    var post_data = {
      per_page: query.per_page,
      page: 1,
      search: value,
      sort_by: "",
      sort_direction: ''
    }
    console.log("value", value)
    setQuery(post_data)
    getAll(post_data);

  }

  const Functioncall = (i) => {

    var data = (((1 - 1) * 10 + 1) - 30)

    console.log("s.sort_by", query.sort_by)


  }


  const onSorting = (key, direct) => {


    var post_data = {
      per_page: query.per_page,
      page: query.page,
      search: '',
      sort_by: key ? key : '',
      sort_direction: direct
    }
    console.log("key", key)
    console.log("direct", direct)

    setQuery(post_data)
    getAll(post_data);



  }






  const TableHeadings = [
    {
        name: "S.No",
        icon: "Yes",
        sort: "desc",
    },
    {
        name: "Title",
    },
    {
        name: "User Type",
        icon: "Yes",
        key: "userType.type",
        sort: ""
    },
  
  
    {
        name: "Action",
    },
]


  return (
    <div className="d-flex">
      <div>

      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

        <div style={{ height: "100%" }}>
          <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

            {/*  */}


            <div className="contanier-fluid">
              <h4 className="profile-tilte">Email Content Management</h4>
            </div>

            <div className="col-lg-12 ">
              <section className="panel Orpage_box">

                <header class="Orvalidation_form">
                  <h6 className="list_organ">List of Email Content</h6>




                </header>

                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />




                <div className="table-colorOrgan mob-overflow">
                  <table >
                    <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />

                    {/* <thead >
                      <tr>
                        <th className="color">S.No</th>
                        <th className="color">Title</th>
                        <th className="color">User Type</th>
                        <th className="color">Action</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {emailcontentsList.map((e, i) => (

                        <tr className="tabledata">
                          {query.sort_direction == "asc" && query.sort_by == "" ?
                            <td className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                            :
                            <td className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                          }
                          <td>{e.title}</td>
                          <td>{e.user_type?.type ? e.user_type.type : "-"}</td>
                          <td>
                            <div className="dropdown">
                              <button  className="dropdown_alert">
                                <span className="">▼</span>
                              </button>
                              <div className="dropdown-content">
                                <Link to={`/emailcontents_edit/${e.id}`}> Edit</Link>


                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      }

                    </tbody>
                  </table>
                </div>

                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


              </section>

            </div>





















          </div>
        </div>
      </div>
    </div>










  );
}

