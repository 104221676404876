import React, { useEffect, useState } from "react";
import {
	CDBBtn,
	CDBProgress,
	CDBTable,
	CDBTableHeader,
	CDBTableBody,
	CDBContainer,
	CDBLink
} from "cdbreact";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import "./Dashboard.css";
import axios from "axios";
import { PROFILE_URL, REQUIREMENT_URL } from "../../constant";
import { ApiCall } from "../../Config/API";
import swal from "sweetalert";
import { useApiResponseContext } from "../../Config/API/ApiResponseContext";

export const ProfileSettings = ({ location }) => {

	const [error, setError] = useState({});
	const [SavedImage, setSavedImage] = useState('');

	const [UpdatedSucess, setUpdatedSucess] = useState(false);

	const Type = localStorage.getItem("user_type")


	const [States, setStates] = useState([]);
	const [logo, Setlogo] = useState('')
	const [logoimage, Setlogoimage] = useState('')
	const [profiledetails, SetProfiledetails] = useState({
		user_name: "",
		first_name: "",
		last_name: "",
		email: "",
		mobile: "",
		address: "",
		city: "",
		state_id: "",
		zipcode: "",
		image: "",
		logo: ''
	})

	useEffect(() => {


		getAll();
	}, []);
	const { dispatch } = useApiResponseContext();
	// const token = localStorage.getItem("user_type");

	const getAll = async () => {
		const token = localStorage.getItem("IDMS_TOKEN");

		// try {
		// 	const Response = await axios({
		// 		method: "get",
		// url: `${PROFILE_URL}` + "edit",
		// 		headers: {
		// 			Authorization: token
		// 		}
		// 	})



		// 	console.log("Response.data.user.name====>", Response.data)

		// 	localStorage.setItem("Name", Response.data.user.name)

		// 	localStorage.setItem("Image", Response.data.user.image)
		// 	setStates(Response.data.states)

		// 	SetProfiledetails(Response.data.user)



		// 	if (Response.data.user.organization) {
		// 		localStorage.setItem("Logo", Response.data.user.organization.logo)
		// 		Setlogoimage(Response.data.user.organization.logo)
		// 	}




		// }

		// catch (err) {
		// 	console.log("errr====>", err)

		// }
		var data = {
			"method": "get",
			"route_name": "/profile/edit",
		}

		const Response = await ApiCall(data,dispatch)

		if (Response.success) {

			console.log("Response", Response.success)



			localStorage.setItem("Name", Response.success.user.name)

			localStorage.setItem("Image", Response.success.user.image)
			setStates(Response.success.states)

			SetProfiledetails(Response.success.user)
			const not = localStorage.getItem("Notification_Show")
			console.log("NOTifiiiiiiiiiiii====>", not)

				dispatch({ type: 'NOTIFICATION', payload: localStorage.getItem("Notification_Show") })
			




			if (Response.success.user.organization) {
				localStorage.setItem("Logo", Response.success.user.organization.logo)
				Setlogoimage(Response.success.user.organization.logo)
			}





		}
		else {
			console.log("Error", Response.error.errors)
			setError(Response.error.errors)
		}

	};

	const UpdateFunc = async () => {

		var formdata = new FormData();
		console.log(logo, "logo");
		formdata.append("first_name", profiledetails.first_name);
		formdata.append("last_name", profiledetails.last_name);
		formdata.append("email", profiledetails.email);
		formdata.append("mobile", profiledetails.mobile);
		formdata.append("address", profiledetails.address);
		formdata.append("city", profiledetails.city);
		formdata.append("state_id", profiledetails.state_id);
		formdata.append("zipcode", profiledetails.zipcode);
		formdata.append("image", SavedImage);
		formdata.append("logo", logo);
		formdata.append("_method", "PUT");


		var data = {
			"method": "POST",
			"route_name": "/profile",
			"body": formdata
		}

		const Response = await ApiCall(data,dispatch)

		if (Response.success) {

			console.log("Response", Response.success)

			localStorage.setItem("Name", Response.success.name)

			localStorage.setItem("Image", Response.success.image)

			if (Response.success.organization) {
				localStorage.setItem("Logo", Response.success.organization.logo)

			}

			dispatch({ type: 'PROFILE', payload: Response.success });
			// setUpdatedSucess(true)
			swal("Success!", "Profile Updated Sucessfully!", "success")
			getAll()
			setError({})

			setTimeout(() => {

				swal.close();

			}, 1500);


		}
		else {
			console.log("Error", Response.error.errors)
			setError(Response.error.errors)
		}


	}


	const handleInputChange = (fieldName, e) => {


		console.log("e", e.target.value)

		if (fieldName == "image" && e.target.files[0]) {

			SetProfiledetails((prevData) => ({
				...prevData,
				[fieldName]: e.target.files[0],
			}));

			setSavedImage(e.target.files[0])

		}
		else if (fieldName == "logo" && e.target.files[0]) {

			// SetProfiledetails((prevData) => ({
			// 	...prevData,
			// 	[fieldName]: e.target.files[0],
			// }));

			Setlogo(e.target.files[0])

		}
		// else if (fieldName == "state_id") 
		// {
		// 	console.log("e", e.target.value)


		// }
		else {

			SetProfiledetails((prevData) => ({
				...prevData,
				[fieldName]: e.target.value,
			}));
		}



	}
	return (
		<div >
			<div>

			</div>
			<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

				{/* <div > */}
				<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>





					{/*  */}

					<div className="contanier-fluid">
						<h4 className="profile-tilte">Profile</h4>
					</div>

					<div className="container-fluid">
						<div className="row borderbox">
							<h4 className="Editprofile">Edit Profile</h4>
							<div className="col-sm-12 col-md-6" style={{ marginTop: '18px' }}>


								<div className="form-group form-textSetting">
									<label for="" className="col-sm-3 control-label">User Name</label>
									<div className="col-sm-7" style={{ margintop: '5px' }} >
										{profiledetails.user_name}
									</div>

								</div>

								<div className="form-group form-textSetting">

									<label for="" className="col-sm-3 control-label">First Name <span className="required">*</span>
									</label>

									<div className="col-sm-7">
										<input
											onChange={(e) => handleInputChange("first_name", e)}
											value={profiledetails.first_name} type="text" className="form-control validate[required,custom[phone]]" id="mobile" name="data[User][mobile]" required />
										{error.first_name ?
											<span className="span-error"  >{error.first_name}</span>
											: null}

									</div>
								</div>
								<div className="form-group form-textSetting">
									<label for="" className="col-sm-3 control-label" >Last Name <span className="required">*</span></label>
									<div className="col-sm-7">
										<input
											onChange={(e) => handleInputChange("last_name", e)}
											value={profiledetails.last_name} type="text" className="form-control validate[required,custom[phone]]" required />
										{error.last_name ?
											<span className="span-error"  >{error.last_name}</span>
											: null}
									</div>
								</div>
								<div className="form-group form-textSetting">
									<label for="" className="col-sm-3 control-label">Email  <span className="required">*</span></label>
									<div className="col-sm-7">
										{/* <input
											onChange={(e) => handleInputChange("email", e)}
											value={profiledetails.email} type="text" className="form-control validate[required,custom[phone]] " required />
										{error.email ?
											<span className="span-error"  >{error.email}</span>
											: null} */}
										{profiledetails.email}
									</div>
								</div>
								<div className="form-group form-textSetting">
									<label for="" className="col-sm-3 control-label">Mobile <span className="required">*</span></label>
									<div className="col-sm-7">
										<input
											onChange={(e) => handleInputChange("mobile", e)}
											value={profiledetails.mobile} type="text" className="form-control validate[required,custom[phone]]" id="mobile" maxlength="12" required />
										{error.mobile ?
											<span className="span-error"  >{error.mobile}</span>
											: null}
									</div>
								</div>

								<div className="form-group form-textSetting">
									<label for="inputEmail3" class="col-sm-3 control-label ">Address <span class="required">*</span></label>
									<div className="col-sm-7">
										<textarea
											onChange={(e) => handleInputChange("address", e)}
											value={profiledetails.address} className="form-control inputbox-height validate[required] note-style" id="address" cols="5" name="data[User][address]" ></textarea>
										{error.address ?
											<span className="span-error"  >{error.address}</span>
											: null}
									</div>
								</div>

							</div>
							<div className="col-md-6" style={{ marginTop: '18px' }}>


								<div className="form-group form-textSetting">
									<label for="inputPassword3" className="col-sm-3 control-label">City <span className="required">*</span></label>
									<div className="col-sm-7">
										<input
											onChange={(e) => handleInputChange("city", e)}
											value={profiledetails.city} type="text" className="form-control inputbox-height validate[required]" id="city" name="data[User][city]" />
										{error.city ?
											<span className="span-error"  >{error.city}</span>
											: null}
									</div>
								</div>
								<div className="form-group form-textSetting">
									<label for="inputPassword3" className="col-sm-3 control-label">State <span className="required">*</span></label>
									<div className="col-sm-7">
										{/* <input
												onChange={(e) => handleInputChange("state_id", e)}
												value={profiledetails.state_id} type="text" className="form-control inputbox-height validate[required]" id="state" name="data[User][state]" /> */}

										<select value={profiledetails.state_id} onChange={(value) => handleInputChange("state_id", value)} className="statedrop">
											<option value="">Select State</option>
											{States.map((e) => (
												<option value={e.id}>{e.name}</option>
											))}
										</select>

										{error.state_id ?
											<span className="span-error"  >{error.state_id}</span>
											: null}
									</div>
								</div>
								<div className="form-group form-textSetting">
									<label for="inputPassword3" className="col-sm-3 control-label">Zipcode <span className="required">*</span></label>
									<div className="col-sm-7">
										<input
											onChange={(e) => handleInputChange("zipcode", e)}

											value={profiledetails.zipcode} type="text" className="form-control inputbox-height validate[required]" id="zipcode" name="data[User][zipcode]" />
										{error.zipcode ?
											<span className="span-error"  >{error.zipcode}</span>
											: null}
									</div>
								</div>

								<div className="form-group form-textSetting">
									<label for="inputPassword3" className="col-sm-3 control-label">Image</label>
									<div className="col-sm-7 ">
										<input
											onChange={(e) => handleInputChange("image", e)}
											type="file" name="data[User][image]" className="validate[custom[image]]" />
										{error.image ?
											<span className="span-error"  >{error.image}</span>
											: null}
									</div>


								</div>

								<div className="form- form-textSetting">
									<div className="col-sm-7 ">
										<img alt="panelImage" src={SavedImage ? URL.createObjectURL(SavedImage) : profiledetails.image} className='logoimg-setting' />
										<br />
									</div>


								</div>
								{Type == "Admin" ?
									<div>


										<div className="form-group form-textSetting">
											<label for="inputPassword3" className="col-sm-3 control-label">Logo</label>
											<div className="col-sm-7 ">
												<input
													onChange={(e) => handleInputChange("logo", e)}
													type="file" name="data[User][image]" className="validate[custom[image]]" />

											</div>


										</div>

										<div className="form- form-textSetting">
											<div className="col-sm-7 ">
												<img alt="panelImage" src={logo ? URL.createObjectURL(logo) : logoimage} className='logoimg-setting' />
												<br />
											</div>


										</div>
									</div>
									: null}


								<div className="form-group">
									<div className=" col-sm-7">
										<button onClick={() => UpdateFunc()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
									</div>
								</div>


							</div>
						</div>






					</div>





























					{/* </div> */}
				</div>
			</div>







		</div >

	);
}
