import React from 'react';

const Page404 = () => {


  
  return (
   
     <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for might be in another castle.</p>
      <img
        src={require("../../assets/404.png")} // Replace with your own image URL
        alt="Mario 404"
        className="not-found-image"
      />
    </div>

  );
};

export default Page404;