import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApiProvider } from './Config/API/ApiResponseContext';
import NoInternetConnection from './Components/NoInternetConnection';
import { Spinners } from './Components/Spinner';



ReactDOM.render(
  <React.StrictMode>
    <ApiProvider>
      <NoInternetConnection>
        <Spinners />
        <App />
      </NoInternetConnection>
    </ApiProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



// In Super Admin Dashboard.js as setting page
// In Super Admin Tables.js as Email mgmt page -> Edit page as Tab_emailedit.js
// In Super Admin Profile.js as Organization mgmt page ->add organization as Organization_pro.js ->view page as OrganizationView.js
// In Super Admin Hero404.js as Admin mgmt page ->add admin as Admin_hero.js ->view page as AdminView.js





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
