import React, { useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";
import { useApiResponseContext } from "../Config/API/ApiResponseContext";
import { Button, Modal, Spinner } from "react-bootstrap";





export const Spinners = () => {

    const { apiState } = useApiResponseContext();

    console.log("responseStateaaaaaaaa", apiState?.loading)

    return (


        <Modal
            show={apiState?.loading}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
 
        >
            {/* <div className="spnnner">
                <div className="spinner-style"> */}


            {/* <Modal.Body> */}
                {/* <RotatingLines
                    strokeColor="#315189"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                /> */}
                <Button className="btn-spinner" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Loading...
                </Button>

            {/* </Modal.Body> */}
            {/* </div>
            </div> */}
        </Modal>






    )




}

