// import React from 'react';
// import Routes from './Routes';
// import './App.css';

// import Sidebar from './Sidebar';
// import Navbar from './Navbar';
// import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';


// function App() {

//   return (
//     // <div>
//       <Router>

//         <div className="dashboard d-flex">
//           <div>
//             
//           </div>
//           <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
//             

//             <p>djfjdfjdjf</p>

//           </div>
//         </div>
//         {/* <Routes /> */}

//       </Router>

//     // </div>
//   );
// }

// export default App;

import React, { useState } from 'react';
import { Route, NavLink, BrowserRouter, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './App.css';
import { CDBNavbar } from 'cdbreact';
import { Header } from './Navbar.style';
import './pages/allstyle.css'
import AllRoutes from './AllRoutes';
import { LoginContent } from './pages/Login/Login';
import { Navigate, } from 'react-router-dom';
import { Spinner } from './Components/Spinner';
import { MainScreen } from './Components/MainScreen';
import Page404 from './pages/Page404/Page404';



// const OtherRoutes = () => {


//   const [loader, setLoader] = useState(false)

//   // const { responseState } = useLoaderReducer();

//   // console.log("responseState", responseState)
// // 
//   return (
//     <div className="dashboard d-flex">
//       <div>
//         <Sidebar />
//       </div>
//       <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
//         <Navbar />
//         {loader ?
//           <div className='spinner-styletop'>
//             <Spinner />
//           </div>
//           :
//           <AllRoutes />
//         }

//       </div>
//     </div>
//   )
// }

const token = localStorage.getItem("IDMS_TOKEN");

const App = () => {



  return (


    <BrowserRouter>

      <Routes>

        <Route path="/" element={token ? <Navigate to="profilesettings" /> : <Navigate to="login" />} />

        <Route path="/login" element={<LoginContent />} />

        <Route path="*" element={token ? <MainScreen /> : <Navigate to="login" />} />
        <Route path="/404" element={<Page404 />} />
        
      </Routes >

    </BrowserRouter>
  )


}

export default App;

