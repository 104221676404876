import React, { useEffect, useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";
import apiReducer from "../Config/API/Reducer";
import { useApiResponseContext } from "../Config/API/ApiResponseContext";




export const PaginationContent = (props) => {


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page
    const [totalItems, setTotalItems] = useState(12); // Total count of items
    const { apiState } = useApiResponseContext();

    const [success, setsuccess] = useState(null);



    // const { success } = apiState

    console.log("props", props.content)



    useEffect(() => {
        fetchDataForCurrentPage();
        if (props.content) {
            setsuccess(props.content)

        }
        else {

            setsuccess(apiState.success)

        }
    }, [apiState.success, props.content]);


    console.log("success", success)

    // Calculate the total number of pages
    const totalPages = Math.ceil(success && ((success.total) / (success.per_page)));
    // console.log("totalPages", totalPages)
    // Calculate the range of items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Simulate fetching data for the current page
    const fetchDataForCurrentPage = () => {
        // In a real application, you would fetch data from an API based on the range
        // of items to display on the current page.
        // const dataForCurrentPage = /* Fetch data here */
        //     console.log(dataForCurrentPage);
    };

    // Handle page change
    const handlePageChange = (newPage, click) => {


        console.log("click", click)
        if (!click) {
            setCurrentPage(newPage);
            props.PageClick(newPage)
        }


    };

    // Fetch data for the current page when the component mounts or the page changes



    return (
        success && success.total ?
            <div className="Previous_organ ">
                <div>
                    {/* <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Showing {props.page_show.from} to {props.page_show.to} of {props.page_show.total} entries</h5> */}
                    <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Showing {success && success.from} to {success && success.to} of {success && success.total} entries</h5>

                </div>
                <div>
                    <button disabled={success && Number(success.current_page) == 1 ? true : false} onClick={() => handlePageChange(success && Number(success.current_page) - 1)} className={`Next_button`}> ← Previous</button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button className={success && Number(success.current_page) == index + 1 ? "no1_button_selected" : 'no1_button_unselected'} key={index} onClick={() => handlePageChange(index + 1, success && Number(success.current_page) == index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <button disabled={success && (Number(success.current_page) == Number(success.last_page)) ? true : false} onClick={() => handlePageChange(success && Number(success.current_page) + 1)} className="Next_button">Next →</button>

                </div>
            </div >
            : null
    )




}

