import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "../../Profile.css"
import "../../Dashboard.css";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ORGANIZATION_URL } from "../../../constant";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";


export const OrganizationEdit = () => {

    const navigate = useNavigate()
    const [OrgVal, setOrgtVal] = useState('');
    const [OrgVal1, setOrgtVal1] = useState('');
    const [OrgVal2, setOrgtVal2] = useState('');
    const [OrgVal3, setOrgtVal3] = useState('');
    const [image, setImage] = useState('');
    const [changeimage, setChangeimage] = useState('');

    const [error, setError] = useState({});

    const location = useLocation();


    const { id } = useParams()

    const org_id = id


    useEffect(() => {


        fetchData();
    }, []);

    const Img_Func = (event) => {
        console.log("eveee", event.target.value)
        setChangeimage(event.target.files[0])

    };

    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${ORGANIZATION_URL}/` + org_id,
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            setOrgtVal(Response.data.company_name)
            setOrgtVal1(Response.data.address)
            setOrgtVal2(Response.data.email)
            setOrgtVal3(Response.data.mobile)
            setImage(Response.data.logo)
        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };




    const handleOrgan = (event) => {

        setOrgtVal(event.target.value);

        // console.log(event);


    };
    const handleOrgan1 = (event) => {

        setOrgtVal1(event.target.value);
    };
    const handleOrgan2 = (event) => {

        setOrgtVal2(event.target.value);
    };
    const handleOrgan3 = (event) => {

        setOrgtVal3(event.target.value);
    };

    const handleSubmit = async () => {

        console.log(OrgVal);
        console.log(OrgVal1);
        console.log(OrgVal2);
        console.log(OrgVal3);

        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("image====>", image)


        // var body = {
        //     "company_name": OrgVal,
        //     "address": OrgVal1,
        //     "email": OrgVal2,
        //     "mobile": OrgVal3,

        //     "logo": image
        // }
        var formdata = new FormData();

        formdata.append("company_name", OrgVal);
        formdata.append("address", OrgVal1);

        formdata.append("email", OrgVal2);

        formdata.append("mobile", OrgVal3);
        formdata.append("logo", changeimage);
        formdata.append("_method", 'PUT');


        try {
            const Response = await axios({
                method: "POST",
                url: `${ORGANIZATION_URL}/` + org_id,
                data: formdata,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)

            swal("Success!", "Organization Updated Successfully!", "success")
            setTimeout(() => {
                navigate("/organizations")

                swal.close();
            }, 1500);
        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
            setError(err.response.data.errors)

        }


    }





    return (
        <div className="d-flex profile">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Organization Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Organization</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/organizations" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>


                                </header>




                                <div className="row Organization_sub" >

                                    <div className="col-sm-12 col-md-6">




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">Organization Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={OrgVal}
                                                    onChange={handleOrgan} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.company_name}</span>
                                            </div>
                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="inputEmail3" class="col-sm-4 control-label">Address <span class="required">*</span></label>
                                            <div className="col-sm-7">
                                                <textarea className="form-control inputbox-height validate[required]" style={{ height: 124 }} value={OrgVal1}
                                                    onChange={handleOrgan1} ></textarea>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.address}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={OrgVal2}
                                                    onChange={handleOrgan2} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Mobile No.<span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={OrgVal3}
                                                    onChange={handleOrgan3} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.mobile}</span>
                                            </div>
                                        </div>

                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Logo <span></span></label>
                                            <div className="col-sm-7 ">
                                                <input type="file" onChange={Img_Func} />
                                            </div>

                                        </div>

                                        <div className="form- form-textSetting">

                                            <div className="col-sm-7 ">
                                                {/* <img alt="panelImage" src={image} className='logoimg-setting' /> */}
                                             
                                                    <img alt="no image" src={changeimage ? URL.createObjectURL(changeimage) : image} className='logoimg-setting' />
                                                
                                                <br />
                                            </div>



                                        </div>

                                        <div className="form-group">
                                            <div className=" col-sm-7 button_top">
                                                <button onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                            </div>
                                        </div>


                                    </div>




                                </div>




                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}