import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { ORGANIZATION_URL } from "../../../constant";
import { useLocation, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Spinner } from "../../../Components/Spinner";
import moment from "moment/moment";
// Admin page here as hero

export const OrganizationView = () => {


    const [AdVal, setAdVal] = useState('');
    const [AdVal1, setAdVal1] = useState('');
    const [AdVal2, setAdVal2] = useState('');
    const [AdVal4, setAdVal4] = useState('');
    const [AdVal5, setAdVal5] = useState('');
    const [Created, setCreated] = useState('');

    const location = useLocation();

    const { id } = useParams()

    const org_id = id

    const [Viewdetails, setViewdetails] = useState({})

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${ORGANIZATION_URL}/` + org_id,
                headers: {
                    Authorization: token
                }
            })

            // console.log("Response====>", Response.data)
            // setViewdetails(Response.data)

            console.log("Response====>", Response.data)
            setAdVal(Response.data.company_name)
            setAdVal1(Response.data.address)
            setAdVal2(Response.data.email)
            setCreated(Response.data.status.name)
            setAdVal4(Response.data.created_at)
            setAdVal5(Response.data.logo)

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    return (

        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Organization Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Organization</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/organizations" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Organization Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal1}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {AdVal2}

                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span className={`${Created === 'Active' ? 'green-status' : Created === 'In active' ? 'red-status' : 'yellow-status'}`}>{Created}</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {moment(AdVal4).format('MM-DD-YYYY hh:mm A')}   
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Logo</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', }} >
                                                <img alt="No Image" src={AdVal5} style={{ width: '100px' }} />
                                            </div>
                                        </div>

                                    </div>

                                </div>




                            </section>

                        </div>



                    </div>
                </div>
            </div>
        </div>





    );
}
