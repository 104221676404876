import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link } from 'react-router-dom';


import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ADMIN_URL, ORGANIZATION_URL, REQUIREMENT_URL } from "../../../constant";
import { useParams } from "react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import moment from "moment";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";

// Admin page here as hero

export const RequirementView = () => {


    useEffect(() => {


        getAll();
    }, []);

    const navigate = useNavigate()

    const location = useLocation();

    const [fillabledata, setFillabledata] = useState({
        "client_name": "",
        "partner_id": '',
        "title": "",
        "location": "",
        "job_type_id": '',
        "requirement_rate_type_id": '',
        "partner_price": '',
        "visa_id": '',
        "is_local_candidate_only": '',
        "contract_length": '',
        "position": '',
        "description": "",
        "recruiter_price_c2c_from": '',
        "recruiter_price_c2c_to": '',
        "recruiter_price_w2_from": '',
        "recruiter_price_w2_to": '',
        'notes': ''
    }
    )
    const [Visatype, setVisatype] = useState({})
    const [Jobtype, setJobtype] = useState({})
    const [partner, setPartner] = useState({})
    const [req_noteslist, setReq_noteslist] = useState([])

    const { id } = useParams()

    const { dispatch } = useApiResponseContext();




    const Requirement_id = id





    const getAll = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");



        try {
            const Response = await axios({
                method: "get",
                url: `${REQUIREMENT_URL}/` + Requirement_id,
                headers: {
                    Authorization: token
                },

            })

            console.log("Response====>", Response.data)
            setFillabledata(Response.data)
            setVisatype(Response.data.visa)
            setJobtype(Response.data.job_type)
            setPartner(Response.data.partner)
            setReq_noteslist(Response.data.requirement_notes)


        }

        catch (err) {
            console.log("errr====>", err)
        }

    };


    const DeleteFunc = async (id) => {


        var data = {
            "method": "DELETE",
            "route_name": "requirements/" + Requirement_id + "/requirement_notes/" + id,

        }

        const Response = await ApiCall(data,dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success)
            dispatch({ type: 'NOTIFICATION', payload: Response.headers })
            localStorage.setItem("Notification_Show", Response.headers);

            swal("Success!", "Notes Deleted Successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }




    };

    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Requirement</h6>
                                    <Link onClick={() => navigate(-1)} type="button" className=" btn btn-default Organ_addbtn"  ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.requirement_no}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement Title</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.title}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {Jobtype.type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (1099)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {fillabledata.recruiter_price_c2c_from} Per Hour -  $ {fillabledata.recruiter_price_c2c_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (W2)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ {fillabledata.recruiter_price_w2_from} Per Hour -  $ {fillabledata.recruiter_price_w2_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.description}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Note</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.notes}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Restrictions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {Visatype.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Local Candidate Only</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.is_local_candidate_only == 0 ? "NO" : "YES"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contract Length</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.contract_length}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">No of Positions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.position}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Client Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.client_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(fillabledata.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div><br></br>


                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Partner Details</h6>




                                </header>



                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Partner Rate</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ {fillabledata.partner_price}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.partner?.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Company Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.company_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.mobile}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.email}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Notes</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.notes ? partner.notes : "-"}

                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">FEDID/SSN</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {partner.fedid_ssn ? partner.fedid_ssn : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {partner.address ? partner.address : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">City</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.city}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">State</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {partner.state_id}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(partner.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </section>
                        </div>

                        {req_noteslist.length > 0 ?

                            <div className="col-lg-12 ">
                                <section className="panel Orpage_box">

                                    <header class="Orvalidation_form">
                                        <h6 className="list_organ">View Requirement Notes</h6>

                                    </header>
                                    <div className="notes_scroll">


                                        {req_noteslist.map((e, i) => (



                                            <div className="req_notesview row" >

                                                <div className="col-sm-1" style={{ fontWeight: '300' }}>
                                                    {i + 1}.
                                                </div>
                                                <div className="col-sm-9" >

                                                    <div style={{ fontWeight: '300', fontStyle: "initial" }} >
                                                        {e.body}
                                                    </div>
                                                    <div style={{ fontWeight: '300', fontStyle: "italic", marginTop: 8 }} >
                                                        {e.created_by.name} - {e.created_by.user_type}
                                                    </div>

                                                </div>

                                                <div className="col-sm-2 reqnote_time" >
                                                    {moment(e.created_at).format('MM-DD-YYYY hh:mm A')}
                                                </div>
                                                {e.is_can_delete ?
                                                    <div onClick={() => DeleteFunc(e.id)} className="close-icon-container">
                                                        <img alt="panelImage" src="../../img/pane/cross.png" className='logo_notes' />
                                                    </div>
                                                    : null}
                                            </div>




                                        ))
                                        }
                                    </div>
                                </section>
                            </div>
                            : null}




                    </div>
                </div>
            </div>
        </div>





    );
}
