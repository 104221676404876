import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link } from 'react-router-dom';

import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import { STAFF_URL } from "../../../constant";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";

// Admin page here as hero

export const StaffView = () => {


    const location = useLocation();


    const { id } = useParams()

    const staff_id = id

    const [stValq, setstValq] = useState('');
    const [stVal1q, setstVal1q] = useState('');
    const [stVal2q, setstVal2q] = useState('');
    const [stVal3q, setstVal3q] = useState('');
    const [stVal4q, setstVal4q] = useState('');
    const [stVal5q, setstVal5q] = useState('');
    const [HeadRecruiter, setHeadRecruiter] = useState('');




    useEffect(() => {


        fetchData();
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${STAFF_URL}/` + staff_id,
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            setstValq(Response.data.first_name)
            setstVal1q(Response.data.last_name)
            setstVal2q(Response.data.user_type)
            setstVal3q(Response.data.email)
            setstVal4q(Response.data.status.name)
            setHeadRecruiter(Response.data.head_recruiter)
            setstVal5q(Response.data.created_at)
          

        }

        catch (err) {
            console.log("errr====>", err.response.data)
        }

    };



    return (

        <div className="d-flex">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Staff Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Staff</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/staffs" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">First Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stValq}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Last Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal1q}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal2q.type}
                                            </div>
                                        </div>
                                        {stVal2q.id == 5 ?
                                            <div className="OrganView" >
                                                <label for="" className="col-sm-4 control-label">Assigned to head recruiter</label>
                                                <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                    {HeadRecruiter.name}
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {stVal3q}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Status</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span className={` ${stVal4q === 'Active' ? 'green-status' : stVal4q === 'In active' ? 'red-status' : 'yellow-status'}`}>{stVal4q}</span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(stVal5q).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div>






                    </div>
                </div>
            </div>
        </div>





    );
}
