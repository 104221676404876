import React, { useEffect, useState } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar, CDBInput } from "cdbreact";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ApiCall } from "./Config/API";
import swal from "sweetalert";
import { useApiResponseContext } from "./Config/API/ApiResponseContext";

const Navbar = (prop) => {
  const navigate = useNavigate()


  const [Profile, setProfile] = useState({})
  const [Name, setName] = useState('')
  const [Image, setImage] = useState('')

  const type = localStorage.getItem("user_type")





  const Logout = () => {

    localStorage.removeItem("IDMS_TOKEN");
    localStorage.removeItem("user_type");

    localStorage.removeItem("Name")

    localStorage.removeItem("Image")


    // swal("Success!", "Logged Out Sucessfully!", "success")


    navigate("/")

    // setTimeout(() => {
    //   swal.close();
    // }, 2000);




  }
  const profile_name = localStorage.getItem("Name")

  const profile_logo = localStorage.getItem("Image")


  const { apiState } = useApiResponseContext();

  const { dispatch } = useApiResponseContext();


  useEffect(() => {


    GetProfileApi()

    console.log("apiState", (apiState?.profile_data))


  }, [apiState?.profile_data])

  const GetProfileApi = async () => {

    console.log("propprop", prop.success)

    var data = {
      "method": "GET",
      "route_name": "/profile",

    }

    const Response = await ApiCall(data,dispatch)
    if (Response.success) {


      console.log("Response Profile navbar", Response)

      // setProfile(Response.success)
      setName(Response.success.name)
      setImage(Response.success.image)

      // dispatch({ type: 'PROFILE', payload: Response.success });
    }
    else {
      console.log("Error", Response.error)

    }

  }






  return (
    <Header style={{ background: "#162031", color: "#fff" }}>
      <CDBNavbar dark expand="md" scrolling className="end-navContent ">
        {/* <div>
          <img alt="panelImage" src="img/pane/logo_alter.png" className='logomm' end-navContent />
        </div> */}
        <div className="toggle">
          <div className="ml-auto logonav ">
            <h4 className='nav-font'>{Name}({type})</h4>

            <img src={Image} className='logoimgnavv' />

            {/* <div class="dropdown">
              <button className="dropdown-button">
                <span className="dropdown-icon">▼</span>
              </button>
              <div className="dropdown-content dropdown-left">
                <a href="/ProfileSettings"><i className="fas fa-cogs arrowkey"></i> Setting</a>
                <a href="/ChangePassword"><i className="fas fa-user arrowkey"></i>Change Password</a>
                <a href="" onClick={() => Logout()} ><i className="fas fa-sign-out-alt arrowkey"></i> Logout</a>
              </div>
            </div> */}

            <div class="dropdown">
              <button className="dropdown-button">
                <span className="dropdown-icon">▼</span>
              </button>

            </div>



          </div>

          <div class="dropdown-content dropdown-left">
            <Link to="/profilesettings"><i className="fas fa-cogs arrowkey"></i> Setting</Link>
            <Link to="/changepassword"><i className="fas fa-user arrowkey"></i>Change Password</Link>
            <Link to={"/login"} onClick={() => Logout()}><i className="fas fa-sign-out-alt arrowkey"></i> Logout</Link>
          </div>
        </div>
      </CDBNavbar>
    </Header>
  );
}

export default Navbar;
