import React, { useEffect, useState } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { ApiCall } from "../../../Config/API";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { TableHeader } from "../../../Components/TableHeader";
// import Search from 'react-select';

const Headoption = [

    { value: 'Robert', label: 'Robert' },

];


export const Partner_Shortlist = () => {

    const [Headreq, setHeadreq] = useState(null);





    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });


    useEffect(() => {

        getAll(query);

    }, []);

    const [myshortList, setMyShortList] = useState([]);

    const { apiState } = useApiResponseContext();

    const { dispatch } = useApiResponseContext();

    const getAll = async (query) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `partner_short_lists?`,
            'query': query

        }
        const Response = await ApiCall(data, dispatch)
        if (Response.success) {

            console.log("Response", Response.success)
            setMyShortList(Response.success.data)



            dispatch({ type: 'SUCCESS', payload: Response.success });

        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: query.sort_by,
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }




    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Name",
            icon: "Yes",
            key: "candidate.first_name",
            sort: ""
        },
        {
            name: "Requirement No.",
            icon: "Yes",
            key: "requirement.requirement_no",
            sort: ""
        },
        {
            name: "Requirement Title",
            icon: "Yes",
            key: "requirement.title",
            sort: ""
        },
        {
            name: "Interview On",
            icon: "Yes",
            key: "interview_at",
            sort: ""
        },
        {
            name: "Round",
            icon: "Yes",
            key: "rounds_count",
            sort: ""
        },
        {
            name: "Partner Status",
            icon: "Yes",
            key: "partner_status",
            sort: ""
        },
        {
            name: "Candidate Accepted",
            icon: "Yes",
            key: "is_accepted_by_candidate",
            sort: ""
        },

        {
            name: "Action",
            notes: "no"
        },

    ]

    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);

    }

    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Shortlist Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Partner Shortlist</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/shortlists" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                    </div>
                                </header>



                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />



                                <div className="table-colorOrgan mob-overflow">
                                    <table >

                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />

                                        {/* <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color">Name</th>
                                                <th className="color">Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">Interview On</th>
                                                <th className="color">Round</th>
                                                <th className="color">Partner Status</th>
                                                <th className="color">Candidate Accepted</th>
                                                <th className="color">Action</th>

                                            </tr>
                                        </thead> */}


                                        {myshortList.length > 0 ?
                                            <tbody>
                                                {myshortList.map((e, i) => (
                                                    <tr className="tabledata">

                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td style={{ width: 76 }} className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td style={{ width: 76 }} className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                        }

                                                        <td style={{ width: 100 }}>
                                                            <Link to={`/partner_shortlists_view/${e.id}`}> {e.candidate.name}</Link>


                                                        </td>
                                                        <td >{e.requirement.requirement_no}</td>
                                                        <td >{e.requirement.title}</td>
                                                        <td >{e.interview_at ? moment(e.interview_at).format('MM-DD-YYYY hh:mm A') : 'N/A'}</td>
                                                        <td > {e.rounds_count ?  e.rounds_count : 'N/A'}</td>
                                                        <td style={{ textAlign: "center" }}>{e.partner_status ? e.partner_status : 'N/A'}</td>
                                                        <td style={{ textAlign: "center", width: 165 }}>{e.is_accepted_by_candidate == 1 ? "Yes" : e.is_accepted_by_candidate == 0 ? "No" : "N/A"}</td>
                                                        <td>
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    <Link to={`/partner_shortlists_view/${e.id}/${e.candidate.id}`}> View</Link>
                                                                    <Link to={`/partner_shortlists_edit/${e.id}/${e.candidate.id}`}> Edit</Link>




                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                            :
                                            <tbody>

                                                <tr>
                                                    <td colSpan="10" style={{ textAlign: 'center' }}>No Record Found</td>
                                                </tr>


                                            </tbody>
                                        }
                                    </table>
                                </div>


                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />


                            </section>

                        </div>





















                    </div>
                </div>
            </div>
        </div>










    );
}

