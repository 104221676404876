const BASE_URL = "https://phplaravel-1231498-4435282.cloudwaysapps.com/api/"



export const LOGIN_URL = `${BASE_URL}` + 'login'

export const LOGOUT_URL = `${BASE_URL}` + 'logout'


export const PROFILE_URL = `${BASE_URL}` + 'profile/'



export const ORGANIZATION_URL = `${BASE_URL}` + `super_admins/organizations` 

export const ADMIN_URL = `${BASE_URL}` + `super_admins/users` 
export const ADMIN_Uuuu = `${BASE_URL}` + `super_admins/users/create` 




export const STAFF_URL = `${BASE_URL}` + `admins/staffs` 
export const STAFF_DROP = `${BASE_URL}` + `admins/staffs/create`


export const JOBTYPE_URL = `${BASE_URL}` + `admins/job_types`


export const VISA_URL = `${BASE_URL}` + `admins/visas`
export const VISA_DROP = `${BASE_URL}` + `admins/visas/create`

export const PARTNER_URL = `${BASE_URL}` + `account_managers/partners`

// Requirements
export const REQUIREMENT_URL = `${BASE_URL}` + `account_managers/requirements`

export const REQUIREMENT_ASSIGN_URL = `${BASE_URL}` + `account_managers/requirement_assigns`


