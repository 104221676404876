import React, { useEffect, useState } from "react";
// import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { JOBTYPE_DROP, VISA_DROP, VISA_URL } from "../../../constant";
import { Link } from "react-router-dom";
import swal from "sweetalert";



export const VisaType_Add = () => {


    const navigate = useNavigate()




    const [visaTypes, setVisaTypes] = useState([]);
    const [error, setError] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("IDMS_TOKEN");
                const response = await axios.get(`${VISA_DROP}`, {
                    headers: {
                        Authorization: token
                    }
                });

                // if (response.status !== 200) {
                //     throw new Error('Network response was not ok');
                // }

                const data = response.data;
                console.log("fdfd", response.data)

                setVisaTypes(data.visa_types);
            } catch (error) {

            }
        };

        fetchData();
    }, []);



    const [visaVal, setvisaVal] = useState('');
    const [visaVal1, setvisaVal1] = useState('');


    const handleVisa = (event) => {

        setvisaVal(event.target.value);


    };
    const handleVisa1 = (value) => {

        setvisaVal1(value);
    };


    const handleSubmit = async () => {

        console.log(visaVal);
        console.log(visaVal1);


        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "visa_type_id": visaVal1,
            "name": visaVal,

        }


        try {
            const Response = await axios({
                method: "POST",
                url: `${VISA_URL}`,
                data: body,
                headers: {
                    Authorization: token
                }
            });


            console.log("Response ===>", Response.data);



            swal("Success!", "VisaType Added Successfully!", "success")

            setTimeout(() => {
                navigate("/visatype");

                swal.close();
            }, 1500);






        } catch (err) {
            console.log("Error Response ===>", err.response.data);
            setError(err.response.data.errors)


        }
    }
    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Visa Type Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ" style={{ fontWeight: '500', fontSize: '16px' }}>Add Visa Type</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/visatype" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">

                                        <div className="form-group form-textSetting">

                                            <label for="" className="col-sm-3 control-label">Visa Type <span className="required">*</span></label>


                                            <div className="col-sm-12">
                                                <form className="checkbox_visa">



                                                    <div className="form-check" >
                                                        {visaTypes.map((visaType) => (
                                                            <label key={visaType.id} className="form-check-label">
                                                                <input type="radio" onChange={() => handleVisa1(visaType.id)} name="visaType" value={visaType.type} className="form-check-input" />
                                                                {visaType.type}

                                                            </label>

                                                        ))}


                                                    </div>
                                                  
                                                    {/* <div>{error}</div> */}
                                                </form>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.visa_type_id}</span>

                                            </div>

                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Visa <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" value={visaVal}
                                                    onChange={handleVisa} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.name}</span>

                                            </div>

                                        </div>




                                    </div>

                                    <div className="form-group">
                                        <div className=" col-sm-7">
                                            <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>





                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}