import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { saveAs } from 'file-saver';


import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ADMIN_URL, ORGANIZATION_URL, REQUIREMENT_URL } from "../../../constant";
import { useParams } from "react-router-dom";
import { ApiCall } from "../../../Config/API";
import swal from "sweetalert";
import moment from "moment";
import { PaginationContent } from "../../../Components/PaginationContent";
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";
import { TableHeader } from "../../../Components/TableHeader";

// Admin page here as hero
const options1 = [

    { value: 'Krish', label: 'Krish' },

];
const options2 = [

    { value: 'Krish001@gmail', label: 'Krish001@gmail' },

];
const options3 = [

    { value: '4528761302', label: '4528761302' },

];
const options4 = [

    { value: 'MCA', label: 'MCA' },

];

export const Head_SelectCandidate_Add = () => {



    const navigate = useNavigate()

    const location = useLocation();

    const [fillabledata, setFillabledata] = useState({
        // "client_name": "",
        // "HeadRecruiter_id": '',
        // "title": "",
        // "location": "",
        // "job_type_id": '',
        // "requirement_rate_type_id": '',
        // "HeadRecruiter_price": '',
        // "visa_id": '',
        // "is_local_candidate_only": '',
        // "contract_length": '',
        // "position": '',
        // "description": "",
        // "recruiter_price_c2c_from": '',
        // "recruiter_price_c2c_to": '',
        // "recruiter_price_w2_from": '',
        // "recruiter_price_w2_to": '',
        // 'notes': ''
    }
    )
    const [Visatype, setVisatype] = useState({})
    const [Jobtype, setJobtype] = useState({})
    const [HeadRecruiter, setHeadRecruiter] = useState({})
    const [Requirement, setRequirement] = useState({})
    const [Requirement_status, setRequirement_status] = useState({})
    const [candidateslist, setCandidateslist] = useState([]);

    const [req_noteslist, setReq_noteslist] = useState([])


    const [Name, setName] = useState(null);
    const [EmailCandidate, setEmailCandidate] = useState(null);
    const [ConatctNo, setConatctNo] = useState(null);
    const [Qualification, setQualification] = useState(null);

    const [checkbox, setCheckbox] = useState(false);

    const [Assignnedlist, setAssignnedlist] = useState([]);

    const { id } = useParams()





    const Requirement_id = id


    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });
    useEffect(() => {


        getAll(query);
    }, []);

    const { dispatch } = useApiResponseContext();
    const { apiState } = useApiResponseContext();

    const getAll = async (query) => {
        const token = localStorage.getItem("IDMS_TOKEN");


        var data = {
            "method": "get",
            "route_name": "requirements/" + Requirement_id + "/candidate_selects?",
            "query": query

        }

        const Response = await ApiCall(data,dispatch)

        if (Response.success) {

            console.log("Response.success...........", Response.success)
            setFillabledata(Response.success.requirement)
            setVisatype(Response.success.requirement.visa)
            setJobtype(Response.success.requirement.job_type)


            setCandidateslist(Response.success.candidates.data)
            dispatch({ type: 'SUCCESS', payload: Response.success.candidates });

        }
        else {
            console.log("Error", Response.error)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

        // try {
        //     const Response = await axios({
        //         method: "get",
        //         url: `${REQUIREMENT_URL}/` + Requirement_id,
        //         headers: {
        //             Authorization: token
        //         },

        //     })

        //     console.log("Response====>", Response.data)
        //     setFillabledata(Response.data)
        //     setVisatype(Response.data.visa)
        //     setJobtype(Response.data.job_type)
        //     setHeadRecruiter(Response.data.HeadRecruiter)
        //     setReq_noteslist(Response.data.requirement_notes)


        // }

        // catch (err) {
        //     console.log("errr====>", err)
        // }

    };


    const DeleteFunc = async (id) => {


        var data = {
            "method": "DELETE",
            "route_name": "requirements/" + Requirement_id + "/requirement_notes/" + id,

        }

        const Response = await ApiCall(data,dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success)

            getAll(query);
            swal("Success!", "Notes Deleted Successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }




    };
    const PdfDownload = async (id) => {
        var data = {
            "method": "GET",
            "route_name": "candidates/" + id + '/files/resume',
            "res_type": 'blob'
        }

        const Response = await ApiCall(data,dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }


    }


    const selectOption = async (id, e) => {

        const data = [...Assignnedlist]

        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = Assignnedlist.filter(item => item !== id);
            // console.log(ssidArray);
            setAssignnedlist(ssidArray)

        } else {
            data.push(id)

            // console.log("data", data)

            setAssignnedlist(data)


        }



    }
    const SelectCandidates = async () => {



        const bodydata = {
            "candidate_ids": Assignnedlist
        }



        var data = {
            "method": "POST",
            "route_name": "requirements/" + Requirement_id + '/candidate_selects',
            'body': bodydata
        }

        const Response = await ApiCall(data,dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Your candidates has been assigned successfully.", "success")
            getAll(query);
            setAssignnedlist([])
            setCheckbox(true)
            setTimeout(() => {
                swal.close();
                setCheckbox(false)

            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }




    }

    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: "",
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }
    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct,

        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);

    }





    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Name",
            icon: "Yes",
            key: "first_name",
            sort: ""
        },

        {
            name: "Contact No.",
        },
        {
            name: "Qualification",
        },
        {
            name: "Visa Type",

        },
        {
            name: "Resume",

        },
        {
            name: "Select",
           

        },
        {
            name: "Status",
            icon: "Yes",
            key: "requirementStatus.name",
            sort: ""
        },

    ]




    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 90px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">View Requirement</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn"

                                        to="/head_myrequirements_list"
                                    // onClick={() => navigate.goBack()}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>



                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.requirement_no}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Requirement Title</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.title}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {Jobtype.type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Job Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (1099)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {fillabledata.recruiter_price_c2c_from} Per Hour -  $ {fillabledata.recruiter_price_c2c_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Recruiter Rate (W2)</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                $ {fillabledata.recruiter_price_w2_from} Per Hour -  $ {fillabledata.recruiter_price_w2_to} Per Hour
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.description}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Note</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.notes}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Restrictions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {Visatype.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Local Candidate Only</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.is_local_candidate_only == 0 ? "NO" : "YES"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contract Length</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.contract_length}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">No of Positions</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.position}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Client Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {fillabledata.client_name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created on</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                {moment(fillabledata.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </section>

                        </div><br></br>


                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Candidate</h6>

                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/candidates_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Candidate</Link>
                                        {/* <Link type="button" className=" btn btn-default Organ_addbtn" to="/candidates" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link> */}

                                    </div>

                                </header>


                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />



                                {/* <div className="row ">


                                    <div className="col-md-6 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Name</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Name-]"
                                                        defaultValue={Name}
                                                        onChange={setName}
                                                        options={options1}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <div className="" >
                                            <label for="inputPassword3" className="col-sm-3 text-candidate ">Email</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Email-]"
                                                        defaultValue={EmailCandidate}
                                                        onChange={setEmailCandidate}
                                                        options={options2}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-6 candidate-row">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Contact.No</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Contact no-]"
                                                        defaultValue={ConatctNo}
                                                        onChange={setConatctNo}
                                                        options={options3}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-6">

                                        <div className="">
                                            <label for="inputPassword3" className="col-sm-3 text-candidate">Qualification</label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Qualification-]"
                                                        defaultValue={Qualification}
                                                        onChange={setQualification}
                                                        options={options4}
                                                        styles={{

                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13px', // Change the font size as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>






                                </div> */}



                                <div className="table-colorOrgan mob-overflow" >
                                    <table>
                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct,)} />

                                        {/* <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Name</th>

                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Contact No.</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Qualification</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Visa Type</th>

                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Resume</th>
                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Select</th>

                                                <th className="color"><i className="fas fa-sort padding_sort"></i>Status</th>
                                            </tr>
                                        </thead> */}
                                        {candidateslist.length > 0 ?
                                            <tbody>
                                                {candidateslist.map((e, i) => (
                                                    <tr>
                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td className="tr-sno">{((apiState?.success?.total) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td className="tr-sno">{((query.page - 1) * 10 + 1) + i}</td>
                                                        }
                                                        <td > <Link to={`/candidates_view/${e.id}`}  > {e.name}</Link></td>

                                                        <td>{e.mobile ? e.mobile : "-"}</td>
                                                        <td>{e.qualification}</td>
                                                        <td>{e.visa.name}</td>

                                                        <td >

                                                            {/* <a href={e.resume}
                                                            download="Example-PDF-document"
                                                            target="_blank"
                                                            rel="noopener noreferrer"   ><i class="fa fa-download"></i> Download</a> */}
                                                            <Link
                                                                onClick={() => PdfDownload(e.id)}
                                                            >
                                                                <i className="fas fa-download"> </i>Download
                                                            </Link>

                                                        </td>
                                                        <td>
                                                            <input checked={checkbox ? false : undefined} onChange={() => selectOption(e.id)} type="checkbox" />

                                                        </td>
                                                        <td>{e.requirement_status.name}</td>

                                                    </tr>

                                                ))
                                                }





                                            </tbody>


                                            : <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <div className="Previous_save">

                                    <div>
                                        {Assignnedlist.length > 0 ?
                                            <Link onClick={() => SelectCandidates()} type="button" className="btn btn-default Organ_addbtn  " ><i className="fa fa-save arrowkey"></i>save</Link>
                                            : candidateslist.length > 0 &&
                                            <div className="btn Organ_addbtn cursor_notallowed " ><i className="fa fa-save arrowkey"></i>save</div>
                                        }

                                    </div>
                                </div>


                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />



                            </section>

                        </div>



                    </div>
                </div>
            </div>
        </div>





    );
}
