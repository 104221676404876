import React, { useState } from "react";
import { InfinitySpin, MutatingDots, RotatingLines } from "react-loader-spinner";




export const Search_PageDrop = (props) => {

    const PageClickFunc = (e) => {


        console.log("e", e.target.value)

        props.PageDropClick(e.target.value)
    }

  
    return (

        <div className="Organ_dropdown1 ">
            <div className="Organ_dropdown">
                <select onChange={(e) => PageClickFunc(e)} id="dropdown">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>

                </select>
                <h5 className="recordpage">records per page</h5>
            </div>
            <div className="Organ_dropdown">
                <h5 className="recordpage">Search</h5>
                <input onChange={(e) => props.OnSearch(e.target.value)} type="text" className="search_width" />
            </div>
        </div>
    )




}

