import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import {
    CDBNavbar,
    CDBNavBrand,
    CDBNavbarNav,
    CDBNavToggle,
    CDBNavItem,
    CDBNavLink,
    CDBBtn,
    CDBCollapse
} from "cdbreact";
import '../../Profile.css';
import Select from 'react-select';
import { ApiCall } from "../../../Config/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import swal from "sweetalert";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext";



// Admin page here as hero

export const Partner_Shortlist_Edit = () => {

    const [PartnerStatusE, setPartnerStatusE] = useState('');
    const { dispatch } = useApiResponseContext();
    const [candidatedetails, setCandidatedetails] = useState({});
    const [PartnerStatusEoption, setPartnerStatusEoption] = useState([

        { value: 'Rejected', label: 'Rejected' },
        { value: 'Selected', label: 'Selected' }

    ]);


    const [selectdate, setSelectdate] = useState('');
    const [dateformat, setSateformat] = useState('');

    const [rounds, setRounds] = useState('');
    const [managercomment, setmanagercomment] = useState('');
    const [candidate_select, setCandidate_select] = useState('');
    const [partnercomment, setPartnercomments] = useState('');

    const [second_screen, setSecond_screen] = useState(false);

    const [Error, setError] = useState({});
    const navigate = useNavigate()



    const { req_id, cand_id, id } = useParams();


    useEffect(() => {


        // GetApi()
        getAll()
    }, [])


    const getAll = async () => {


        var data = {
            "method": "get",
            "route_name": "partner_short_lists/" + req_id + "/edit",

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success.short_list)

            setCandidatedetails(Response.success.candidate)
            setSateformat(Response.success.short_list.interview_at)
            setRounds(Response.success.short_list.rounds_count)
            setmanagercomment(Response.success.short_list.account_manager_comments)



            if (Response.success.short_list.interview_at) {

                setSecond_screen(true)
                const initialDate = new Date(Response.success.short_list.interview_at);
                setSelectdate(initialDate)

                setPartnercomments(Response.success.short_list.partner_comments)
                setCandidate_select(Response.success.short_list.is_accepted_by_candidate)
                setPartnerStatusE(Response.success.short_list.partner_status)

            }
        }
        else {
            console.log("Error", Response.error)

        }




    };
    const PdfDownload = async (id, type) => {

        var data = {
            "method": "GET",
            "route_name": "candidates/" + cand_id + '/files/' + type,
            "res_type": 'blob'
        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            saveAs(Response.success, 'downloaded_resume.pdf');


        }
        else {
            console.log("Error", Response.error)

        }

    }


    const handleDateChange = (date) => {

        var daaa = moment(date).format('MM/DD/YYYY hh:mm A')
        console.log("date", (date))

        setSateformat(daaa)

        setSelectdate(date);
    };







    const InterviewShecdule = async (type) => {

        console.log("partnercomment", PartnerStatusE)

        var data = {
            "method": "PUT",
            "route_name": "partner_short_lists/" + req_id,
            "body": {
                "form": type,
                "interview_at": dateformat,
                "rounds_count": rounds,
                "account_manager_comments": managercomment,
                "partner_status": PartnerStatusE,
                "is_accepted_by_candidate": candidate_select,
                "partner_comments": partnercomment
            }

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)



            swal("Success!", "The partner shortlist information updated successfully.", "success")

            setError({})

            setTimeout(() => {
                swal.close();
                navigate("/partner_shortlists")

            }, 1500);


        }
        else {
            console.log("Error", Response.error)
            setError(Response.error.errors)
        }

    }
    return (

        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}



                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Shortlist Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Candidate Detail</h6>

                                    <Link type="button" className=" btn btn-default Organ_addbtn"
                                        onClick={() => navigate(-1)}
                                    ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>




                                </header>
                                <div className="row Organization_sub">
                                    <div className="col-sm-12 col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.candidate_id ? candidatedetails.candidate_id : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Email ID</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.email}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Contact No.</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.mobile}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Date of Birth</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.dob}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Location</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.location}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Address</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.address ? candidatedetails.address : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">City</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.city ? candidatedetails.city : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">State</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.state ? candidatedetails.state : "-"}


                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Zipcode</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.zipcode}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Geographical Constraints</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_geographical == 1 ? 'Yes' : "No"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Barred</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.is_barred == 1 ? 'Yes' : "No"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Description</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.description ? candidatedetails.description : "-"}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created By</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails?.created_by?.name}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">User Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails?.created_by?.user_type}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Created On</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {/* {candidatedetails.created_at} */}
                                                {moment(candidatedetails.created_at).format('MM-DD-YYYY hh:mm A')}

                                            </div>
                                        </div>



                                    </div>

                                    <div className="col-md-6">


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <span>
                                                    {candidatedetails.is_employer == 1 ? 'Yes' : "No"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Employer Name</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.employer_name ? candidatedetails.employer_name : "-"}
                                            </div>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">1099 or W2</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.employer_rate_type ? candidatedetails.employer_rate_type : "-"}
                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Qualification</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.qualification}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Skills</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.skills}

                                            </div>
                                        </div>


                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Type</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.visa_name ? candidatedetails.visa_name : "-"}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Visa Attachment</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> </i>Download
                                            </div> */}

                                            <Link onClick={() => PdfDownload(id, "visa_attachment")}>
                                                <i className="fas fa-download"> </i>Download
                                            </Link>
                                        </div>

                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Reason</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                {candidatedetails.reason}

                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Candidate Rate</label>
                                            <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >

                                                $ {candidatedetails.candidate_rate}
                                            </div>
                                        </div>
                                        <div className="OrganView" >
                                            <label for="" className="col-sm-4 control-label">Resume</label>
                                            {/* <div className="col-sm-7" style={{ margintop: '5px', fontWeight: '300' }} >
                                                <i className="fas fa-download"> </i>Download
                                            </div> */}

                                            <Link onClick={() => PdfDownload(id, "resume")}>
                                                <i className="fas fa-download"> </i>Download
                                            </Link>
                                        </div>







                                    </div>

                                </div>




                            </section>

                        </div>





                        <div className="container-fluid">
                            <div className="row borderbox">
                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Update Partner Shortlist</h6>


                                </header>


                                <div className="col-sm-12 col-lg-6" style={{ marginTop: '18px' }}>




                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Interview On<span className="required"> *</span></label>
                                        <div className="col-sm-7">
                                            <DatePicker
                                                style={{ width: 479 }}
                                                className="interview_class"
                                                selected={selectdate}
                                                minDate={new Date()}
                                                onChange={handleDateChange}
                                                dateFormat="MM-dd-yyyy h:mm aa"
                                                showTimeSelect
                                                timeFormat="hh:mm aa"
                                                timeIntervals={15}
                                                timeCaption="Time"

                                            />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.interview_at}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Round <span className="required"> *</span></label>
                                        <div className="col-sm-7">
                                            <input value={rounds} onChange={(e) => setRounds(e.target.value)} type="text" className="form-control validate[required,custom[phone]]" required />
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.rounds_count}</span>
                                        </div>
                                    </div>
                                    <div className="form-group form-textSetting">
                                        <label for="" className="col-sm-3 control-label">Comments<span className="required"> *</span></label>
                                        <div className="col-sm-7">
                                            <textarea value={managercomment} onChange={(e) => setmanagercomment(e.target.value)} className="form-control inputbox-height validate[required] note-style" placeholder="Enter Your Comments Here" ></textarea>
                                            <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.account_manager_comments}</span>

                                        </div>
                                    </div>

                                    <div className="form-group" >

                                        <div onClick={() => InterviewShecdule("account_manager")} className=" col-sm-7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <button type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>


                                </div>

                                {/* <label for="dateInput">Select a date:</label>
                                <input type="date" id="dateInput" name="dateInput" onfocus="(type='date')" /> */}

                                {second_screen ?
                                    <div className="col-md-6" style={{ marginTop: '18px' }}>




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Partner Status<span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Partner Status-]"
                                                        // defaultValue={PartnerStatusE}
                                                        value={PartnerStatusEoption.find(option => option.label === PartnerStatusE)}
                                                        onChange={(e) => setPartnerStatusE(e.label)}
                                                        options={PartnerStatusEoption}
                                                    />
                                                </div>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.partner_status}</span>

                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Candidate Status<span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <form className="checkbox_visa">
                                                    <div className="form-check" >
                                                        <input checked={candidate_select == 1 && true} onChange={() => setCandidate_select(1)} className="form-check-input" type="radio" name="radioOptions" id="radioOption1" value="option1" />
                                                        <label className="form-check-label" for="radioOption1">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check checkmtop">
                                                        <input checked={candidate_select == 0 && true} onChange={() => setCandidate_select(0)} className="form-check-input" type="radio" name="radioOptions" id="radioOption2" value="option2" />
                                                        <label className="form-check-label" for="radioOption2">
                                                            No
                                                        </label>
                                                    </div>

                                                </form>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.is_accepted_by_candidate}</span>

                                            </div>

                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Comments<span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <textarea value={partnercomment} onChange={(e) => setPartnercomments(e.target.value)} className="form-control inputbox-height validate[required] note-style" placeholder="Enter Your Comments Here..." ></textarea>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{Error.partner_comments}</span>

                                            </div>
                                        </div>



                                        <div className="form-group" >

                                            <div className=" col-sm-7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <button onClick={() => InterviewShecdule("partner")} type="button" className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                            </div>
                                        </div>






                                    </div>

                                    : null}





                            </div>




                        </div>




























                    </div>
                </div>
            </div>
        </div>





    );
}
