import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { Link } from 'react-router-dom';
import { JOBTYPE_URL } from "../../../constant";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";


export const JobType_Edit = () => {

    const navigate = useNavigate()

    const [JobVal, setJobtVal] = useState('');

    const [error, setError] = useState({});

    const location = useLocation();

    const { id } = useParams()
    const job_id = id

    useEffect(() => {


        fetchData();
    }, []);



    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "GET",
                url: `${JOBTYPE_URL}/` + job_id,
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            setJobtVal(Response.data.type)


        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)


        }

    };




    const handlejob = (event) => {

        setJobtVal(event.target.value);

        // console.log(event);


    };

    const handleSubmit = async () => {



        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "type": JobVal,
        }


        try {
            const Response = await axios({
                method: "PUT",
                url: `${JOBTYPE_URL}/` + job_id,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)

            swal("Success!", "JobType Updated Successfully!", "success")
            setTimeout(() => {
                navigate("/jobtype")

                swal.close();
            }, 1500);




        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
            setError(err.response.data.errors)

        }


    }




    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Job Type Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ" style={{ fontWeight: '500', fontSize: '16px' }}>Edit Job Type</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/jobtype" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">



                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Jobtype <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control  " id="firstname" name="data[User][firstname]" value={JobVal}
                                                    onChange={handlejob} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.type}</span>
                                            </div>

                                        </div>




                                    </div>

                                    <div className="form-group">
                                        <div className=" col-sm-7">
                                            <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>





                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}