import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { VISA_URL } from "../../../constant";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";



export const VisaType_Edit = () => {

    const navigate = useNavigate()
    const [visaEVal, setvisaEtVal] = useState('');

    const [visaEVal1, setvisaEtVal1] = useState('');

    const [visaTypes, setVisaTypes] = useState([]);

    const [error, setError] = useState({});

    const location = useLocation();

    const { id } = useParams()
    const visas_id = id

    useEffect(() => {


        fetchData();
    }, []);



    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "GET",
                url: `${VISA_URL}/` + visas_id + "/edit",
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data.visa.visa_type.id)
            // setvisaEtVal(Response.data.visa_type_id)
            // setvisaEtVal(Response.data.visa_type_id || 'active');
            setvisaEtVal1(Response.data.visa.name)
            setVisaTypes(Response.data.visa_types)
            setvisaEtVal(Response.data.visa.visa_type.id)
        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };




    const handlevisa = (event) => {

        setvisaEtVal(event.target.value);

        // console.log(event);


    };
    const handlevisa1 = (event) => {

        setvisaEtVal1(event.target.value);
    };


    const handleSubmit = async () => {



        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "visa_type_id": visaEVal,
            "name": visaEVal1,

            // "_method": "",

        }


        try {
            const Response = await axios({
                method: "PUT",
                url: `${VISA_URL}/` + visas_id,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)

            swal("Success!", "VisaType Updated Successfully!", "success")

            setTimeout(() => {
                navigate("/visatype");

                swal.close();
            }, 1500);

        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
            setError(err.response.data.errors)

        }


    }





    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Visa Type Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ" style={{ fontWeight: '500', fontSize: '16px' }}>Edit Visa Type</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/visatype" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">

                                        <div className="form-group form-textSetting">

                                            <label for="" className="col-sm-3 control-label">Visa Type <span className="required">*</span></label>
                                            <div className="col-sm-12">
                                                <div className="form-check">
                                                    {visaTypes.map((visaType) => (
                                                        <label key={visaType.id} className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={visaType.id}
                                                                checked={visaEVal == visaType.id ? true : false}
                                                                onChange={handlevisa}
                                                            />
                                                            {visaType.type}

                                                        </label>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-3 control-label">Visa <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" value={visaEVal1}
                                                    onChange={handlevisa1} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.name}</span>
                                            </div>

                                        </div>




                                    </div>

                                    <div className="form-group">
                                        <div className=" col-sm-7">
                                            <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                        </div>
                                    </div>





                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}