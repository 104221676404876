import React, { useEffect, useState } from "react";
import "./Login.css"
import axios from "axios";
import { LOGIN_URL } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Modal from 'react-bootstrap/Modal';
import keyss from "../../Config";
// import { BrowserRouter, useNavigate } from "react-router-dom";



export const LoginContent = () => {


    const navigate = useNavigate()

    const [error, setError] = useState({});

    const [inputVal, setinputVal] = useState('');

    const [tokens, settokens] = useState('');
    const token = localStorage.getItem("IDMS_TOKEN");


    // useEffect(() => {

    //     console.log("tokensssss", tokens);
    //     if (tokens) {
    //     }
    //     if (token) {
    //         navigate("/profilesettings");
    //         settokens("")
    //         return 
    //     } 
    //     // settokens(token)


    //     // 
    // }, [tokens])


    const handleInputChange = (event) => {
        setinputVal(event.target.value);
    };
    const [inputVal1, setinputVal1] = useState('');

    const handleInputChange1 = (event) => {
        setinputVal1(event.target.value);
    };
    const handleSubmit = async (event) => {

        console.log(inputVal);
        console.log(inputVal1);


        // console.log("urllllllllll", `${LOGIN_URL}`);

        var body = {
            "user_name": inputVal,
            "password": inputVal1
        }


        try {
            const Responseee = await axios({
                method: "POST",
                url: `${LOGIN_URL}`,
                data: body
            })

            console.log("Responseee", Responseee.data)
            //  console.log("isuserhasnewnotifications", Responseee.headers)

            localStorage.setItem("IDMS_TOKEN", "Bearer " + Responseee.data.plainTextToken)
            localStorage.setItem("user_type", Responseee.data.accessToken.name)
            settokens(Responseee.data.plainTextToken)
            // swal("Success!", "Logged in Successfully!", "success")
            // navigate("/profilesettings")
            window.location.href = "/profilesettings";
            // setTimeout(() => {
            //     swal.close();
            // }, 2000);




        }

        catch (err) {


            console.log("errr====>", err.response.data.errors)

            setError(err.response.data.errors)
        }


    }




    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [forgetemail, setForgetemail] = useState("");

    const [forgetemailerror, setForgetemailerror] = useState({});

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };






    const handleForgotPassword = async (e) => {





        try {
            const Responseee = await axios({
                method: "POST",
                url: keyss.BASE_API + "/forgot_password",
                data: {
                    "email": forgetemail
                }
            })

            console.log("Responseee", Responseee.data)

            setModalIsOpen(false);
            setForgetemailerror({})
            swal("Success!", "Your password details sent to your email address", "success")


            setTimeout(() => {
                swal.close();
            }, 2000);




        }

        catch (err) {


            console.log("errr====>", err.response.data.errors)

            setForgetemailerror(err.response.data.errors)
        }


    }






    return (


        <div>


            <div className="clearfix altimaximg">

                <div className="container">
                    <div className="img_logoNew">
                        <img alt="panelImage" src="img/pane/logo.png" />

                    </div>

                    <div className="row">
                        <div className="col" style={{ padding: "10px" }}>
                            <div className=" login">
                                <h3 className="logins">LOGIN</h3>
                                {/* 
                                <form autoComplete="on">
                                    <div className="form-group">
                                        <label  for="username" className='email-text lab'>Email / Username</label>
                                        <input
                                         className='input-color form-control box' type="text" name="email" value={inputVal}
                                            onChange={handleInputChange} placeholder="Email / Username" required />

                                        <span style={{ color: "red", fontSize: 15 }}>{error.user_name}</span>

                                    </div>
                                    <div className="form-group">
                                        <label for="password" className='email-text lab' >Password</label>
                                        <input
                                            onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                                            name="password"
                                            className=' input-color form-control box'
                                            value={inputVal1}
                                            onChange={handleInputChange1}
                                            type="password"
                                            placeholder="Password"
                                            required />

                                        <span style={{ color: "red", fontSize: 15 }}>{error.password}</span>

                                    </div>

                                    <a  className="btn btn-default submit" type="submit" onClick={handleSubmit}>Submit</a>
                                </form> */}


                                <form autoComplete="on">
                                    <div className="form-group">
                                        <label htmlFor="email" className="email-text lab">
                                            Email / Username
                                        </label>
                                        <input
                                            autoComplete="username"
                                            className="input-color form-control box"
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={inputVal}
                                            onChange={handleInputChange}
                                            placeholder="Email / Username"
                                        // required
                                        />
                                        <span style={{ color: "red", fontSize: 15 }}>{error.user_name}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="email-text lab">
                                            Password
                                        </label>
                                        <input
                                            autoComplete="current-password"
                                            onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="input-color form-control box"
                                            value={inputVal1}
                                            onChange={handleInputChange1}
                                            placeholder="Password"
                                        // required
                                        />
                                        <span style={{ color: "red", fontSize: 15 }}>{error.password}</span>
                                    </div>
                                    <button className="btn btn-default submit" type="button" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </form>


                                <h4 className="forgetpass"><Link onClick={() => setModalIsOpen(true)}>Forgot Password ?</Link></h4>



                            </div>
                        </div>
                        <div className="col">
                            <div className=" logininfo">
                                <ul className="list-unstyled details">
                                    <li >
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space'>Enter Your corporate email.</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>
                                        <span className='icon-space' > Enter Your password</span></li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space' >If you do not know your password,</span>

                                    </li>
                                    <li>
                                        <span className='icon-space1' >click the - "Forgot Password?"</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check icon_check"></i>

                                        <span className='icon-space' > Will send you a temporary password to your  </span>
                                        <li>
                                            <span style={{ marginLeft: '27px' }}>email id.</span>
                                        </li>
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>


                </div >





                <div className="footer">
                    <div className="row container">
                        <div className="col-md-3"  >
                            <img alt="panelImage" src="img/pane/late Icon.jpg" style={{ width: "50%", height: "auto", marginTop: "13px" }} />
                        </div>
                        <div className="col-md-5" >
                            <p className="footer-para">Copyrights <i class="fa fa-copyright" aria-hidden="true"></i>  ALTIMATIX. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-4 socialicon-arrange">
                            <ul class=" socialicon">
                                <li ><a href="#"><i className="fab fa-facebook icon_facebook"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter icon_twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-linkedin icon_linkedin"></i></a></li>
                                <li><a href="#"><i className="fab fa-google icon_google"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>













            </div >


            <Modal
                show={modalIsOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                onHide={closeModal}
            >
                <Modal.Header className="modal-header-forget" closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Forgot Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-body clearfix">
                        <form action="" method="post" id="forgotform" name="forgot">
                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input onChange={(e) => setForgetemail(e.target.value)} type="email" name="data[User][email]" id="email" className="form-control validate[required,custom[email]] box" placeholder="Email" />
                                <span style={{ color: "red", fontSize: 15 }}>{forgetemailerror.email}</span>

                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 mmargin clearfix">
                                    <a href="#" onClick={closeModal}><i className="fa fa-chevron-left"></i> Back to Login</a>
                                </div>
                                <div className="col-sm-6 col-lg-6 ">
                                    <button type="button" onClick={() => handleForgotPassword()} className="btn btn-default forgetbutton">SEND ME !</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button >Close</Button> */}
                </Modal.Footer>
            </Modal>















        </div>
























    );
}
