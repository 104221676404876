import React, { useEffect, useState } from "react";
import { useApiResponseContext } from "../../../Config/API/ApiResponseContext"

import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { ADD_REQUIREMENT_URL, LIST_REQUIREMENT_URL, PARTNER_URL, REQUIREMENT_ASSIGN_URL, REQUIREMENT_URL } from "../../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Link } from "react-router-dom";
// import Search from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ApiCall } from "../../../Config/API";

import { PaginationContent } from "../../../Components/PaginationContent"
import { Search_PageDrop } from "../../../Components/Search_PageDrop";
import { TableHeader } from "../../../Components/TableHeader";
import { RequirementNoteModal } from "../../../Components/RequirementNoteModal";
const Headoption = [

    { value: 'Robert', label: 'Robert' },

];

export const RequirementList = () => {

    const [Headreq, setHeadreq] = useState(null);

    const [RequirementList, setRequirementList] = useState([]);

    const [Assignnedlist, setAssignnedlist] = useState([]);
    const [checkbox, setCheckbox] = useState(false);

    const [notesmodal, setNotesmodal] = useState(false);

    const [notestext, setNotestext] = useState('');
    const [noteserror, setNoteserror] = useState({});

    const [pagevalues, setPagevalues] = useState({
        from: 1,
        to: 10,
        total: 10
    });
    const [sorting, setsorting] = useState('desc');
    const [part_sorting, setPart_sorting] = useState('');


    const [requirement_id, setRequirement_id] = useState('');

    const [total_count, settotal_count] = useState(0);

    const [query, setQuery] = useState({
        per_page: 10,
        page: 1,
        search: '',
        sort_by: "",
        sort_direction: 'desc'

    });


    const navigate = useNavigate()

    const [Headoption, setHeadoption] = useState([]);


    useEffect(() => {
        // var post_data = {
        //     per_page: 10,
        //     page: 1,
        //     search: '',
        //     sort_by: "",
        //     sort_direction: ''
        // }
        getAll(query);

    }, []);



    const { dispatch } = useApiResponseContext();


    const getAll = async (query) => {


        console.log("query", query)
        var data = {
            "method": "get",
            "route_name": `requirements?`,
            'query': query

        }
        const Response = await ApiCall(data, dispatch)
        if (Response.success) {

            console.log("Response", Response.success)
            setRequirementList(Response.success.requirements.data)

            var array = Response.success.head_recruiters.map((e) => ({

                value: e.id,
                label: e.name
            }))

            setHeadoption(array)

            dispatch({ type: 'SUCCESS', payload: Response.success.requirements });
            settotal_count(Response.success.requirements.total)
        }
        else {
            console.log("Error", Response.error.errors)
            dispatch({ type: 'ERROR', payload: Response.error });
        }

    };



    const selectOption = async (id, e) => {


        const data = [...Assignnedlist]



        // Check if the ID is in the array
        if (data.includes(id)) {
            var ssidArray = Assignnedlist.filter(item => item !== id);
            // console.log(ssidArray);

            setAssignnedlist(ssidArray)


        } else {
            data.push(id)

            // console.log("data", data)

            setAssignnedlist(data)


        }



    }


    console.log("Assignnedlist", Assignnedlist)

    const AssignHeadrecruiter = async () => {



        var body = {
            "head_recruiter_id": Headreq.value,
            "requirement_ids": Assignnedlist
        }

        var data = {
            "method": "post",
            "route_name": "requirement_assigns",
            "body": body

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Requirements Assigned Sucessfully!", "success")
            getAll(query)
            setCheckbox(true)

            setAssignnedlist([])
            setHeadreq(null)
            setTimeout(() => {
                swal.close();
                setCheckbox(false)
            }, 1500);


        }
        else {
            console.log("Error", Response.error.errors)

        }




    }



    const NotesSendApi = async () => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + requirement_id + "/requirement_notes",
            "body": {
                'body': notestext
            }
        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response", Response.success)
            swal("Success!", "Your notes added successfully.!", "success")
            setNotesmodal(false)
            setNotestext('')
            setNoteserror({})
            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error.errors)
            setNoteserror(Response.error.errors)
        }
    }


    // console.log("RequirementListsssssssss", RequirementList)
    const PageOnpresFunc = (pagedrop, value,) => {

        var post_data = {
            per_page: pagedrop,
            page: value,
            search: query.search,
            sort_by: query.sort_by,
            sort_direction: query.sort_direction
        }
        console.log("value", value)

        setQuery(post_data)

        getAll(post_data);

    }


    const OnSearchFunc = (value) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: value,
            sort_by: "",
            sort_direction: ''
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);

    }






    const onSorting = (key, direct) => {


        var post_data = {
            per_page: query.per_page,
            page: query.page,
            search: '',
            sort_by: key ? key : '',
            sort_direction: direct
        }
        console.log("key", key)
        console.log("direct", direct)

        setQuery(post_data)
        getAll(post_data);



    }

    const onSortingwithname = (value, value2) => {


        var post_data = {
            per_page: query.per_page,
            page: 1,
            search: '',
            sort_by: value2,
            sort_direction: value
        }
        console.log("value", value)
        setQuery(post_data)
        getAll(post_data);
        // setPart_sorting(value)

    }


    const SerialNo = () => {


        return 50
    }



    const itemsPerPage = 10;
    const totalPages = Math.ceil(30 / itemsPerPage);

    const [currentPage, setCurrentPage] = useState(1);

    const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };



    const TableHeadings = [
        {
            name: "S.No",
            icon: "Yes",
            sort: "desc",
        },
        {
            name: "Requirement No.",
            icon: "Yes",
            key: "requirement_no",
            sort: ""
        },
        {
            name: "Requirement Title",
            icon: "Yes",
            key: "title",
            sort: ""
        },
        {
            name: "Location",
        },
        {
            name: "Partner",
        },
        {
            name: "Partner Rate",
            icon: "Yes",
            key: "partner_price",
            sort: ""
        },
        {
            name: "Active Status",
           
        },
        {
            name: "Current Status",

        },


        {
            name: "Action",
        },
        {
            name: "Assign",
        },
        {
            name: "Notes",
        },
    ]


    const statusChangeFunc = async (req_id, index) => {


        var data = {
            "method": "POST",
            "route_name": "requirements/" + req_id + "/status"

        }

        const Response = await ApiCall(data, dispatch)

        if (Response.success) {

            console.log("Response.success====>", Response.success)


            var array = [...RequirementList]
            array[index].active_status.name = (array[index].active_status.name != "Active" ? "Active" : 'In active')
            setRequirementList(array)


            swal("Success!", "Candidate Status updated successfully!", "success")

            setTimeout(() => {
                swal.close();
            }, 1500);

        }
        else {
            console.log("Error", Response.error)

        }
    }




    return (
        <div className="d-flex">
            <div>

            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

                        {/*  */}


                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Requirement Management</h4>
                        </div>

                        <div className="col-lg-12 ">
                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">List of Requirement</h6>
                                    <div>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/assigned_requirement" ><i className="fas fa-eye arrowkey"></i>View Assigned Requirement</Link>
                                        <Link type="button" className=" btn btn-default Organ_addbtn" to="/requirements_add" ><i className="fa fa-plus-circle arrowkey"></i>Add Requirement</Link>

                                    </div>
                                </header>

                                <div style={{ margin: '15px' }}>
                                    <div className=" form-textSetting">
                                        <label for="inputPassword3" className="col-sm-2 control-label">Assign to Head Recruiter</label>
                                        <div className="col-sm-4">

                                            <div className="">
                                                <Select
                                                    placeholder="[-Search Head Recruiter-]"
                                                    // defaultValue={Headreq}
                                                    onChange={setHeadreq}
                                                    value={Headreq ? Headoption.find(option => option.value === Headreq.value) : null}
                                                    options={Headoption}
                                                />
                                            </div>

                                        </div>
                                        {Headreq && Assignnedlist.length > 0 ?
                                            <div onClick={() => AssignHeadrecruiter()} className=" btn btn-default assign_addbtn "  >Assign</div>
                                            : null}
                                    </div>
                                </div>



                                <Search_PageDrop OnSearch={(value) => OnSearchFunc(value)} PageDropClick={(value) => PageOnpresFunc(value, 1)} />


                                <div className="table-colorOrgan mob-overflow">
                                    <table >

                                        <TableHeader Titles={TableHeadings} Sortfunc={(key, direct) => onSorting(key, direct)} />
                                        {/* <thead >
                                            <tr>
                                                <th className="color">S.No</th>
                                                <th className="color" onClick={() => onSorting(query.sort_direction == 'asc' ? 'desc' : 'asc')}>

                                                    {query.sort_direction == "desc" ?
                                                        <i className="fas fa-sort-up padding_sort"></i>
                                                        :
                                                        <i className="fas fa-sort-down padding_sort"></i>
                                                    }

                                          

                                                    Requirement No.</th>
                                                <th className="color">Requirement Title</th>
                                                <th className="color">Location</th>
                                                <th className="color">Partner</th>
                                                <th className="color">
   
                                                    {part_sorting == "desc" ?
                                                        <i onClick={() => onSortingwithname('asc', 'partner_price')} className="fas fa-sort-up padding_sort"></i>
                                                        :
                                                        part_sorting == "asc" ?
                                                            < i onClick={() => onSortingwithname('desc', 'partner_price')} className="fas fa-sort-down padding_sort"></i>
                                                            :
                                                            <i onClick={() => onSortingwithname('asc', 'partner_price')} className="fas fa-sort padding_sort"></i>
                                                    }

                                                    Partner Rate</th>
                                                <th className="color">Action</th>
                                                <th className="color">Assign</th>
                                                <th className="color">Notes</th>
                                            </tr>
                                        </thead> */}
                                        {RequirementList.length > 0 ?
                                            <tbody>
                                                {RequirementList.map((e, i) => (
                                                    <tr key={i} className="tabledata">

                                                        {query.sort_direction == "asc" && query.sort_by == "" ?
                                                            <td style={{ width: 67 }} className="tr-sno" > {((total_count) - i) - ((query.page - 1) * 10)}</td>
                                                            :
                                                            <td style={{ width: 67 }} className="tr-sno" >{((query.page - 1) * 10 + 1) + i}</td>
                                                        }

                                                        <td style={{ width: 140 }}>
                                                            <Link to={`/requirements_view/${e.id}`}  > {e.requirement_no}</Link>
                                                        </td>

                                                        {/* <td style={{ color: 'blue' }}>{e.requirement_no}</td> */}
                                                        <td style={{ width: 220 }}>{e.title}</td>
                                                        <td>{e.location}</td>
                                                        <td>{e.partner ? e.partner.name : "-"}</td>
                                                        <td style={{ width: 140 }}> $ {e.partner_price}</td>
                                                        <td style={{ width: 110 }}>
                                                            <span 
                                                             style={{ marginLeft: 10 }}
                                                                className={
                                                                    e.active_status.name === 'Active'
                                                                        ? 'green-status'
                                                                        : e.active_status.name === 'In active'
                                                                            ? 'red-status'
                                                                            : e.active_status.name === 'Pending'
                                                                                ? 'yellow-status'
                                                                                : e.active_status.name === 'Trash'
                                                                                && 'red-status'
                                                                }
                                                            >
                                                                {e.active_status.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {e.active_status.name === 'Trash' ?
                                                                <p style={{ marginLeft: 40 }}>-</p>
                                                                :
                                                                <Link onClick={() => statusChangeFunc(e.id, i)}  >{e.active_status.name === 'Active' ? "Click to Deactivate" : "Click to Activate"}</Link>

                                                            }
                                                        </td>


                                                        <td style={{ width: 110 }} >
                                                            <div className="dropdown history_dropdown">
                                                                <button className="dropdown_alert">
                                                                    <span className="">▼</span>
                                                                </button>
                                                                <div className="dropdown-content">
                                                                    {/* <a onClick={() => EditFunc1(e.id)}> Edit</a>
                                                                <a onClick={() => ViewFunc1(e.id)}>View</a> */}
                                                                    <Link to={`/requirements_view/${e.id}`}  > View</Link>
                                                                    <Link to={`/requirements_edit/${e.id}`}  > Edit</Link>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: 67 }}>
                                                            <input disabled={e.active_status.name === 'Active' ? false : true} checked={checkbox ? false : undefined} type="checkbox" onChange={() => selectOption(e.id)} />
                                                        </td>
                                                        <td style={{ width: 67 }}>
                                                            <Link onClick={() => (setNotesmodal(true), setRequirement_id(e.id))}>
                                                                <img alt="panelImage" src="img/pane/notes.jpg" className='logoimgnav2' />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}




                                            </tbody>
                                            :
                                            <tbody>

                                                <tr style={{ textAlign: 'center' }}>
                                                    <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle' }}>No Record Found</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>



                                <PaginationContent PageClick={(value) => PageOnpresFunc(query.per_page, value)} />

                            </section>

                        </div>





















                    </div>
                </div>
            </div >


            {notesmodal ?
                <RequirementNoteModal req_id={requirement_id} closemodal={(value) => value && setNotesmodal(false)} />
                : null}

            {/* <Modal
                show={notesmodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setNotesmodal(false)}
            >
                <Modal.Header className="modal-header" closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Requirement Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <textarea
                        placeholder="Enter your notes..."
                        onChange={(e) => setNotestext(e.target.value)}
                        className="form-control inputbox-height validate[required] note-style" required id="address" cols="5" name="data[User][address]" >

                    </textarea>
                    {noteserror.body ?
                        <span style={{ marginTop: 10 }} className="span-error" >{noteserror.body}</span>
                        : null}
                    
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => NotesSendApi()} type="button" className="btn btn-default Organ_addbtn">Post Notes</button>

                  
                </Modal.Footer>
            </Modal> */}




        </div >










    );
}

