import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import { useNavigate, useLocation } from "react-router-dom";
import { ADMIN_URL } from "../../../constant";
import axios from "axios";
import Select from 'react-select';
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";



export const UserEdit = () => {


    const [selectedOption, setSelectedOption] = useState('');

    const [options, setoptions] = useState([]);
    const [image, setImage] = useState(null);


    const navigate = useNavigate()
    const [AdVal, setAdVal] = useState('');
    const [AdVal1, setAdVal1] = useState('');
    const [AdVal2, setAdVal2] = useState('');

    // const [Addrop, setAddrop] = useState(null);
    const [error, setError] = useState({});


    const location = useLocation();
    const { id } = useParams()

    const org_id = id



    useEffect(() => {


        fetchData3();
    }, []);



    // const onchangefun = (e) => {


    //     console.log("e.target.value",e)
    //     setSelectedOption(e.value)


    // };

    const fetchData3 = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${ADMIN_URL}/` + org_id + "/edit",
                headers: {
                    Authorization: token
                }
            })

            console.log("Response====>", Response.data)
            const array = Response.data.organizations.map((e) => ({

                label: e.company_name,
                value: e.id,
                logo: e.logo

            }))


            setoptions(array)


            setAdVal(Response.data.user.first_name)
            setAdVal1(Response.data.user.last_name)
            setAdVal2(Response.data.user.email)
            setSelectedOption(Response.data.user.organization_id)
            setImage(Response.data.user.organization.logo)
        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    const onchangefun = (e) => {


        console.log("e.target.value", e)
        setSelectedOption(e.value)
        setImage(e.logo)

    };


    const handleAd = (event) => {

        setAdVal(event.target.value);



    };
    const handleAd1 = (event) => {

        setAdVal1(event.target.value);
    };
    const handleAd2 = (event) => {

        setAdVal2(event.target.value);
    };


    const handleSubmit = async () => {

        console.log(AdVal);
        console.log(AdVal1);
        console.log(AdVal2);


        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": AdVal,
            "last_name": AdVal1,
            "email": AdVal2,
            'organization_id': selectedOption
        }


        try {
            const Response = await axios({
                method: "PUT",
                url: `${ADMIN_URL}/` + org_id,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)


            swal("Success!", "Admin Updated Successfully!", "success")
            setTimeout(() => {
                navigate("/user")

                swal.close();
            }, 1500);
        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
            setError(err.response.data.errors)
        }


    }






    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Admin Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Edit Admin</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/user" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>


                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">First Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={AdVal}
                                                    onChange={handleAd} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>
                                            </div>
                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">Last Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={AdVal1}
                                                    onChange={handleAd1} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={AdVal2}
                                                    onChange={handleAd2} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Organization<span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Organization-]"
                                                        value={options.find(option => option.value === selectedOption)}
                                                        onChange={(e) => onchangefun(e)}
                                                        options={options}
                                                    />
                                                </div>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.organization_id}</span>


                                            </div>
                                        </div>

                                        {image ?
                                            <div className="form- form-textSetting">
                                                <div className="col-sm-7 ">
                                                    <img alt="panelImage" src={image} className='logoimg-setting' />
                                                    <br />
                                                </div>


                                            </div>
                                            : null}

                                        {/* <div className="form- form-textSetting">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-7 ">
                                                <img alt="panelImage" src="img/pane/logo_alter.png" style={{ width: '120px', }} />
                                                <br />
                                            </div>

                                        </div> */}



                                        <div className="form-group">
                                            <div className=" col-sm-7">
                                                <button type="button" onClick={() => handleSubmit()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                            </div>
                                        </div>


                                    </div>




                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}