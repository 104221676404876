
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ADMIN_URL, ADMIN_Uuuu, ORGANIZATION_URL } from "../../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";



// const options = [

//     { value: 'Admin1', label: 'Admin1' },
//     { value: 'Admin2', label: 'Admin2' },
//     { value: 'Admin3', label: 'Admin3' },
// ];

export const UserAdd = () => {

    const [selectedOption, setSelectedOption] = useState('');

    const [options, setoptions] = useState([]);

    const navigate = useNavigate()

    const [error, setError] = useState({});
    const [image, setImage] = useState(null);

    const [AdVal, setAdVal] = useState('');
    const [AdVal1, setAdVal1] = useState('');
    const [AdVal2, setAdVal2] = useState('');



    const handleAd = (event) => {

        setAdVal(event.target.value);

        // console.log(event);


    };
    const handleAd1 = (event) => {

        setAdVal1(event.target.value);
    };
    const handleAd2 = (event) => {

        setAdVal2(event.target.value);
    };


    const onchangefun = (e) => {


        console.log("e.target.value", e)
        setSelectedOption(e.value)
        setImage(e.logo)


    };
    useEffect(() => {

        fetchData();

    }, []);


    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${ADMIN_Uuuu}`,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)


            const array = Response.data.organizations.map((e) => ({

                label: e.company_name,
                value: e.id,
                logo: e.logo

            }))


            setoptions(array)


        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    const handleSubmit1 = async () => {

        console.log(AdVal);
        console.log(AdVal1);
        console.log(AdVal2);


        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": AdVal,
            "last_name": AdVal1,
            "email": AdVal2,
            'organization_id': selectedOption
        }


        try {
            const Response = await axios({
                method: "POST",
                url: `${ADMIN_URL}`,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response_Admin====>", Response.data)


            swal("Success!", "Admin Added Successfully!", "success")
            setTimeout(() => {
                navigate("/user")

                swal.close();
            }, 1500);




        }

        catch (err) {
            console.log("Admin_errr====>", err.response.data)
            setError(err.response.data.errors)

        }


    }

    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Admin Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Add Admin</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/user" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">First Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={AdVal}
                                                    onChange={handleAd} />

                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>

                                            </div>
                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">Last Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={AdVal1}
                                                    onChange={handleAd1} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="email" className="form-control inputbox-height validate[required]" value={AdVal2}
                                                    onChange={handleAd2} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>

                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Organization<span className="required">*</span></label>
                                            <div className="col-sm-7">

                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select Organization-]"
                                                        defaultValue={selectedOption}
                                                        onChange={(e) => onchangefun(e)}
                                                        options={options}
                                                    />
                                                </div>
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.organization_id}</span>



                                            </div>
                                        </div>

                                        {image ?
                                            <div className="form- form-textSetting">
                                                <div className="col-sm-7 ">
                                                    <img alt="panelImage" src={image} className='logoimg-setting' />
                                                    <br />
                                                </div>


                                            </div>
                                            : null}



                                        <div className="form-group">
                                            <div className=" col-sm-7">
                                                <button type="submit" onClick={() => handleSubmit1()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                            </div>
                                        </div>


                                    </div>




                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div>

        </div >






    );
}