import React, { useEffect, useState } from "react";
import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import '../../Profile.css';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { STAFF_DROP, STAFF_URL } from "../../../constant";
import swal from "sweetalert";
import { Link } from "react-router-dom";

// const options = [

//     { value: 'Staff1', label: 'Staff1' },
//     { value: 'Staff2', label: 'Staff2' },
//     { value: 'Staff3', label: 'Staff3' },
// ];


export const StaffAdd = () => {

    const [selectedOption1, setSelectedOption1] = useState('');

    const [Soptions, setoptions] = useState([]);

    const [HeadRecruiter, setHeadRecruiter] = useState([]);

    const navigate = useNavigate()


    const [staffVal, setstaffVal] = useState('');
    const [staffVal1, setstaffVal1] = useState('');
    const [staffVal2, setstaffVal2] = useState('');
    const [head_recruiter_id, setHead_recruiter_id] = useState('');

    const [error, setError] = useState({});


    const handlestaff = (event) => {

        setstaffVal(event.target.value);

        // console.log(event);


    };
    const handlestaff1 = (event) => {

        setstaffVal1(event.target.value);
    };
    const handlestaff2 = (event) => {

        setstaffVal2(event.target.value);
    };


    const onchangefun = (e) => {


        console.log("e.target.value", e)
        setSelectedOption1(e.value)


    };
    const onchangefun2 = (e) => {


        console.log("e.target.value", e)
        setHead_recruiter_id(e.value)


    };


    useEffect(() => {

        fetchData();

    }, []);


    const fetchData = async () => {
        const token = localStorage.getItem("IDMS_TOKEN");


        try {
            const Response = await axios({
                method: "get",
                url: `${STAFF_DROP}`,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response====>", Response.data)


            const array = Response.data.user_types.map((e) => ({

                label: e.type,
                value: e.id

            }))


            setoptions(array)

            const array1 = Response.data.head_recruiters.map((e) => ({

                label: e.name,
                value: e.id

            }))


            setHeadRecruiter(array1)



        }

        catch (err) {
            console.log("errr====>", err.response.data.errors)
        }

    };

    const handleSubmit1 = async () => {

        console.log(staffVal);
        console.log(staffVal1);
        console.log(staffVal2);


        const token = localStorage.getItem("IDMS_TOKEN")

        console.log("token====>", token)


        var body = {
            "first_name": staffVal,
            "last_name": staffVal1,
            "email": staffVal2,
            'user_type_id': selectedOption1,
            "headrecuiter_id": head_recruiter_id
        }


        try {
            const Response = await axios({
                method: "POST",
                url: `${STAFF_URL}`,
                data: body,
                headers: {
                    Authorization: token
                }
            })


            console.log("Response_Admin====>", Response.data)
            swal("Success!", "Staff Added Successfully!", "success")
            setTimeout(() => {
                navigate("/staffs")

                swal.close();
            }, 1500);


        }

        catch (err) {
            console.log("Admin_errr====>", err.response.data)
            setError(err.response.data.errors)

        }


    }




    return (
        <div className="d-flex profile">
            <div>
                
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>


                        {/*  */}

                        <div className="contanier-fluid">
                            <h4 className="profile-tilte">Staff Management</h4>
                        </div>



                        <div className="col-lg-12 ">

                            <section className="panel Orpage_box">

                                <header class="Orvalidation_form">
                                    <h6 className="list_organ">Add Staff</h6>
                                    <Link type="button" className=" btn btn-default Organ_addbtn" to="/staffs" ><i className="fa fa-arrow-left arrowkey"></i>Back</Link>

                                </header>



                                <div className="row Organization_sub">

                                    <div className="col-sm-12 col-md-6">




                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">First Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={staffVal}
                                                    onChange={handlestaff} />
                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.first_name}</span>
                                            </div>
                                        </div>


                                        <div className="form-group form-textSetting">
                                            <label for="" className="col-sm-4 control-label">Last Name <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={staffVal1}
                                                    onChange={handlestaff1} />

                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.last_name}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">


                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">Email ID <span className="required">*</span></label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control inputbox-height validate[required]" value={staffVal2}
                                                    onChange={handlestaff2} />

                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.email}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-textSetting">
                                            <label for="inputPassword3" className="col-sm-3 control-label">User Type<span className="required">*</span></label>
                                            <div className="col-sm-7">


                                                <div className="">
                                                    <Select
                                                        placeholder="[-Select User Type-]"

                                                        onChange={(e) => onchangefun(e)}
                                                        options={Soptions}
                                                    />
                                                </div>

                                                <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.user_type_id}</span>



                                            </div>
                                        </div>
                                        {selectedOption1 == 5 ?
                                            < div className="form-group form-textSetting">
                                                <label for="inputPassword3" className="col-sm-3 control-label">Head Recruiter<span className="required">*</span></label>
                                                <div className="col-sm-7">


                                                    <div className="">
                                                        <Select
                                                            placeholder="[-Select Head Recruiter-]"
                                                            onChange={(e) => onchangefun2(e)}
                                                            options={HeadRecruiter}
                                                        />
                                                    </div>

                                                    <span style={{ color: "red", fontSize: 13, marginTop: 10 }}>{error.headrecuiter_id}</span>



                                                </div>
                                            </div>
                                            : null}


                                        <div className="form-group">
                                            <div className=" col-sm-7">
                                                <button type="button" onClick={() => handleSubmit1()} className="btn btn-default Organ_addbtn"><i className="fas fa-save arrowkey"></i> Save Now</button>
                                            </div>
                                        </div>


                                    </div>




                                </div>



                            </section>

                        </div>





                    </div>





                </div>
            </div >

        </div >






    );
}