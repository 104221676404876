import { useLocation } from "react-router-dom";

let   keyss = {};



const Type = localStorage.getItem("user_type")
console.log("Typess", Type)
var user_type = '/'

if (Type == "Super admin") {

  user_type = "/super_admins/"

}
else if (Type == "Admin") {

  user_type = "/admins/"

}
else if (Type == "Account manager") {

  user_type = "/account_managers/"

}
else if (Type == "Head recuiter") {

  user_type = "/head_recruiters/"

}
else if (Type == "Recuiter") {

  user_type = "/recruiters/"

}


if (process.env.NODE_ENV === "production") {
  console.log("Set Production Config");


  const API_URL = "https://phplaravel-1231498-4435282.cloudwaysapps.com/api";
  keyss = {
    secretOrKey: "",
    Recaptchakey: "", //local
    BASE_API: `${API_URL}`,
    API: `${API_URL}` + user_type,
  };
} else {

  console.log("Set Development Config", user_type);


  const API_URL = "https://phplaravel-1231498-4435282.cloudwaysapps.com/api";
  keyss = {
    secretOrKey: "",
    Recaptchakey: "", //local
    BASE_API: `${API_URL}`,
    API: `${API_URL}` + user_type,
  };
}

export default keyss;



